<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="$router.currentRoute.params.id === undefined"
    >
      <h4 class="alert-heading">Error fetching summary data</h4>
      <div class="alert-body">
        No summary found with this summary id. Check
        <b-link class="alert-link" :to="{ name: 'apps-invoice-list' }">
          Summary List
        </b-link>
        for other summaries.
      </div>
    </b-alert>

    <b-row v-if="invoiceData" class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="10" md="9">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <!-- <logo /> -->
                  <h3 class="text-primary invoice-logo">
                    Hulu Migas - Pelayanan Kapal
                  </h3>
                </div>
                <p class="card-text mb-25">PT. Sarana Abadi Lestari</p>
                <p class="card-text mb-25">Kec. Palaran, Kota Samarinda</p>
                <p class="card-text mb-25">
                  +1 (123) 456 7891, +44 (876) 543 2198
                </p>
                <p class="card-text mb-0">Kalimantan Timur 75261</p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Summary
                  <span class="invoice-number"
                    >#{{ form.form_chain_hm_id ?? '-' }}#{{
                      form.form_hulu_migas_id ?? '-'
                    }}#{{ form.form_tug_boat_id ?? '-' }}</span
                  >
                </h4>
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="CalendarIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Date of Application') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatDateTime(form.schedule.created_at) }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="AnchorIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Date of Departure') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatDateTime(form.schedule.sandar_end_time) || '-' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="AnchorIcon" class="mr-75" />
                      <span class="font-weight-bold"> Tanggal Sandar </span>
                    </th>
                    <td class="pb-50">
                      {{
                        formatDateTime(form.schedule.sandar_start_time) || '-'
                      }}
                    </td>
                  </tr>

                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="BookOpenIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Activity') }}
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ form.schedule.kegiatan }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="CpuIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Jenis Kapal') }}
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ form.schedule.jenis_kapal }}
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <feather-icon icon="FlagIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Form Status') }}
                      </span>
                    </th>
                    <td>
                      <b-badge
                        :variant="formStatusVariant(form.schedule.form_status)"
                      >
                        {{ form.schedule.form_status }}
                      </b-badge>
                      <br />
                      <span
                        v-if="
                          form.schedule.jenis_kapal === 'Barge' &&
                          form.schedule.no_rpkro &&
                          form.schedule.no_rpkro_tb &&
                          form.schedule.form_status !== 'KAPAL BERANGKAT'
                        "
                        v-text="` DIIJINKAN SANDAR`"
                      />
                      <span
                        v-else-if="
                          form.schedule.jenis_kapal !== 'Barge' &&
                          form.schedule.no_rpkro &&
                          form.schedule.form_status !== 'KAPAL BERANGKAT'
                        "
                        v-text="` DIIJINKAN SANDAR`"
                      />
                      <span
                        v-else-if="
                          form.schedule.form_status === 'KAPAL BERANGKAT' ||
                          form.schedule.form_status === 'KAPAL SANDAR' ||
                          form.schedule.form_status === 'KEGIATAN DIBATALKAN'
                        "
                        v-text="` `"
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-card-body>
          <!-- Spacer -->
          <hr
            class="invoice-spacing"
            v-if="form.schedule.jenis_kapal === 'Barge'"
          />

          <!-- KAPAL BARGE -->
          <b-card-body
            v-if="form.schedule.jenis_kapal === 'Barge'"
            class="invoice-padding pb-0"
          >
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Main Boat -->
              <div class="col col-md-6 p-0">
                <!-- <h6 class="mb-2">Main Boat:</h6> -->
                <div class="divider my-2">
                  <div class="divider-text">
                    <b-badge :variant="'info'">
                      <feather-icon icon="AnchorIcon" />
                      Main Boat
                      <feather-icon icon="AnchorIcon" />
                    </b-badge>
                  </div>
                </div>

                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nama Kapal') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_river_boat_hm.boat_name }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor PKK') }}
                      </span>
                    </th>
                    <td
                      class="pb-50 cursor-pointer"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form.schedule.no_pkk)"
                    >
                      {{ form.schedule.no_pkk }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('GT') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_river_boat_hm.gt }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('LOA') }}
                      </span>
                    </th>
                    <td class="pb-50">{{ form.dm_river_boat_hm.loa }} M</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Captain') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_river_boat_hm.captain_name }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Captain Telp') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_river_boat_hm.captain_telp }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Shipping Agent') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{
                        form.dm_river_boat_hm.dm_shipping_agent.shipping_name
                      }}
                    </td>
                  </tr>
                </table>
              </div>
              <!-- Tug Boat -->
              <div class="col col-md-6 p-0">
                <!-- <h6 class="mb-2">Main Boat:</h6> -->
                <div class="divider my-2">
                  <div class="divider-text">
                    <b-badge :variant="'info'">
                      <feather-icon icon="AnchorIcon" />
                      Tug Boat
                      <feather-icon icon="AnchorIcon" />
                    </b-badge>
                  </div>
                </div>
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nama Kapal') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.tug_boat.name_tug_boat }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor PKK') }}
                      </span>
                    </th>
                    <td
                      class="pb-50 cursor-pointer"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form.schedule.no_pkk_tug_boat)"
                    >
                      {{ form.schedule.no_pkk_tug_boat }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('GT') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.tug_boat.gt_tug_boat }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('LOA') }}
                      </span>
                    </th>
                    <td class="pb-50">{{ form.tug_boat.loa_tug_boat }} M</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Captain') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.tug_boat.captain_name_tug_boat }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Captain Telp') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.tug_boat.captain_telp_tug_boat }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Shipping Agent') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.tug_boat.dm_shipping_agent.shipping_name }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-card-body>

          <!-- KAPAL NON BARGE -->
          <b-card-body class="invoice-padding pb-0" v-else>
            <div class="divider my-2">
              <div class="divider-text">
                <b-badge :variant="'info'">
                  <feather-icon icon="AnchorIcon" />
                  KAPAL
                  <feather-icon icon="AnchorIcon" />
                </b-badge>
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div class="col col-md-6 p-0">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nama Kapal') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_river_boat_hm.boat_name }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor PKK') }}
                      </span>
                    </th>
                    <td class="pb-50" @click="copyText(form.schedule.no_pkk)">
                      {{ form.schedule.no_pkk }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('GT') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_river_boat_hm.gt }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('LOA') }}
                      </span>
                    </th>
                    <td class="pb-50">{{ form.dm_river_boat_hm.loa }} M</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Captain') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_river_boat_hm.captain_name }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Captain Telp') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_river_boat_hm.captain_telp }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Shipping Agent') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{
                        form.dm_river_boat_hm.dm_shipping_agent.shipping_name
                      }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-card-body>

          <!-- SCHEDULE else -->
          <b-card-body class="invoice-padding pb-0">
            <div class="divider my-2">
              <div class="divider-text">
                <b-badge :variant="'info'">
                  <feather-icon icon="CalendarIcon" />
                  Schedule
                  <feather-icon icon="CalendarIcon" />
                </b-badge>
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div class="col col-md-6 p-0">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Asal Kapal') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.dm_asal_kapal.asal }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Rencana Posisi Jetty') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.schedule.rencana_position_jetty }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Rencana Posisi Baris Jetty') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.schedule.rencana_baris_position_jetty }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Rencana Kapal Sandar') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatDateTime(form.schedule.rencana_sandar) }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-card-body>

          <!-- Attachments -->
          <b-card-body class="invoice-padding pb-0">
            <div class="divider my-2">
              <div class="divider-text">
                <b-badge :variant="'info'">
                  <feather-icon icon="HardDriveIcon" />
                  Upload File
                  <feather-icon icon="HardDriveIcon" />
                </b-badge>
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!--  -->
              <app-timeline-item
                v-for="(item, index) in isHaveFile"
                :key="index"
              >
                <div
                  class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                ></div>
                <p>
                  <b-img
                    :src="icons(item)"
                    height="auto"
                    width="20"
                    class="mr-1"
                  />
                  <span class="align-bottom">
                    <a
                      :href="item.download_link"
                      target="_blank"
                      style="color: grey"
                      rel="noopener noreferrer"
                      >{{ item.original_name }}</a
                    >
                  </span>
                </p>
                <small class="text-muted"
                  >Size {{ item.size }}
                  <strong>[ click name file, to download.]</strong></small
                >
              </app-timeline-item>
            </div>
          </b-card-body>
          <!-- Kegiatan -->
          <b-card-body class="invoice-padding pb-0">
            <div class="divider my-2">
              <div class="divider-text">
                <b-badge :variant="'info'">
                  <feather-icon icon="CheckSquareIcon" />
                  Kegiatan
                  <feather-icon icon="CheckSquareIcon" />
                </b-badge>
              </div>
            </div>

            <b-tabs class="tabs-custom" content-class="" align="center">
              <b-tab v-if="form.form_hulu_migas_id" lazy>
                <template #title>
                  <feather-icon icon="GitMergeIcon" size="30" />
                  <strong>
                    <span style="font-size: 2em">PIPELINE</span>
                  </strong>
                </template>
                <TablePipeLineGood
                  ref="TablePipeLineOutside"
                  :key="form.table_pipe_line"
                  :fhm-i-d="form.form_hulu_migas_id"
                  :is-realisasi="true"
                />
              </b-tab>
              <b-tab v-if="form.form_hulu_migas_id" lazy>
                <template #title>
                  <feather-icon icon="GitMergeIcon" size="30" />
                  <strong>
                    <span style="font-size: 2em">SEMI MEKANIS</span>
                  </strong>
                </template>
                <TableManualHandlingGood
                  ref="TableManualHandlingOutside"
                  :key="form.table_mh"
                  :fhm-i-d="form.form_hulu_migas_id"
                  :is-realisasi="true"
                />
              </b-tab>
            </b-tabs>
            <div class="divider my-2">
              <div class="divider-text">
                <b-spinner v-if="loadingTotalKegiatan" small></b-spinner>
                <span v-else>{{ this.total_realisasi_kegiatan }}</span>
                <br />
                <span>Total Semua Waktu Berkegiatan</span>
              </div>
            </div>
          </b-card-body>

          <!-- Jetty Man -->
          <b-card-body class="invoice-padding pb-0">
            <br />
            <div class="divider my-2">
              <div class="divider-text">
                <b-badge :variant="'info'">
                  <feather-icon icon="MapIcon" />
                  Jetty Man
                  <feather-icon icon="MapIcon" />
                </b-badge>
                <br />
                [ Tujuan Kapal : {{ form?.tujuan_kapal }} ]
              </div>
            </div>
            <b-card-body>
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Main Boat -->
                <div>
                  <!-- <h6 class="mb-2">Main Boat:</h6> -->
                  <div class="divider my-2">
                    <div class="divider-text">
                      <!-- <b-badge :variant="'info'">RKA 1</b-badge> -->
                      <h5>{{ form.dm_river_boat_hm.boat_name }}</h5>
                      <span
                        style="
                          @media (min-width: 390px) and (max-width: 768px) {
                            display: block;
                            word-wrap: break-word;
                            width: 50px;
                            white-space: normal;
                          }
                        "
                      >
                        {{ this.form_realisasi.rka1 }}
                      </span>
                      <br />
                      <strong><span>Sandar → Berangkat</span></strong>
                    </div>
                  </div>
                </div>
                <!-- Tug Boat -->
                <div v-if="form.schedule.jenis_kapal === 'Barge'">
                  <!-- <h6 class="mb-2">Main Boat:</h6> -->
                  <div class="divider my-2">
                    <div class="divider-text">
                      <h5>{{ form.tug_boat.name_tug_boat }}</h5>
                      <span
                        style="
                          @media (min-width: 390px) and (max-width: 768px) {
                            display: block;
                            word-wrap: break-word;
                            width: 50px;
                            white-space: normal;
                          }
                        "
                        >{{ this.form_realisasi.rka2 }}</span
                      >
                      <br />
                      <strong><span>Sandar → Berangkat</span></strong>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>

            <b-tabs class="tabs-custom" content-class="" align="center">
              <b-tab lazy>
                <template #title>
                  <feather-icon icon="GitMergeIcon" size="25" />
                  <strong>
                    <span style="font-size: 1.5em">
                      {{ form.dm_river_boat_hm.boat_name }}
                    </span>
                  </strong>
                </template>
                <!-- content -->
                <div
                  class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                >
                  <NestedIO
                    class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 p-0"
                    uri="realisasi_posisi_kapal"
                    :key="form.table_jetty_position"
                    :model-id="form.form_hulu_migas_id"
                    model="form_hulu_migas"
                    :posisi="jetty_positions"
                    :posisi-baris="jetty_baris_positions"
                    :mode-summary="true"
                    :payload="{
                      model_id: null,
                      model: null,
                      is_first: null,
                      posisi_awal: null,
                      posisi_awal_baris: null,
                      posisi_skrg: null,
                      posisi_skrg_baris: null,
                      reason: null,
                      waktu_pindah: null
                    }"
                    :column-repeat="[
                      {
                        fe_id: 1
                      }
                    ]"
                    :d-t-o="[]"
                    :resolve-fetch="
                      (response) => response.data.realisasi_posisi_kapals
                    "
                    buttonOkTitle=""
                  />
                </div>
              </b-tab>
              <b-tab lazy v-if="form.schedule.jenis_kapal === 'Barge'">
                <template #title>
                  <feather-icon icon="GitMergeIcon" size="25" />
                  <strong>
                    <span style="font-size: 1.5em">
                      {{ form.tug_boat.name_tug_boat }}
                    </span>
                  </strong>
                </template>
                <!-- content -->
                <div
                  class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                >
                  <NestedIO
                    class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 p-0"
                    uri="realisasi_posisi_kapal"
                    :key="form.table_jetty_position"
                    :model-id="form.form_tug_boat_id"
                    model="form_tug_boat"
                    :posisi="jetty_positions"
                    :posisi-baris="jetty_baris_positions"
                    :mode-summary="true"
                    :payload="{
                      model_id: null,
                      model: null,
                      is_first: null,
                      posisi_awal: null,
                      posisi_awal_baris: null,
                      posisi_skrg: null,
                      posisi_skrg_baris: null,
                      reason: null,
                      waktu_pindah: null
                    }"
                    :column-repeat="[
                      {
                        fe_id: 1
                      }
                    ]"
                    :d-t-o="[]"
                    :resolve-fetch="
                      (response) => response.data.realisasi_posisi_kapals
                    "
                    buttonOkTitle=""
                  />
                </div>
              </b-tab>
              <b-tab lazy v-if="form.schedule.jenis_kapal === 'Barge'">
                <template #title>
                  <feather-icon icon="GitMergeIcon" size="25" />
                  <strong>
                    <span style="font-size: 1.5em">Boat Timeline </span>
                  </strong>
                </template>
                <!-- content -->
                <ChainTimeline
                  barge-or-tug="barge"
                  :data-i-d="form.form_chain_hm_id"
                />
              </b-tab>
            </b-tabs>
          </b-card-body>
          <!-- Admin SAL -->
          <b-card-body class="invoice-padding pb-0">
            <br />
            <div class="divider my-2">
              <div class="divider-text">
                <b-badge :variant="'info'">
                  <feather-icon icon="UnlockIcon" />
                  Admin SAL
                  <feather-icon icon="UnlockIcon" />
                </b-badge>
              </div>
            </div>

            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- <b-card border-variant="secondary"> -->
              <div>
                <div
                  class="divider my-2"
                  v-if="form.schedule.jenis_kapal === 'Barge'"
                >
                  <div class="divider-text">
                    <b-badge :variant="'info'">
                      {{ form.dm_river_boat_hm.boat_name }}
                    </b-badge>
                  </div>
                </div>
                <div class="p-0">
                  <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                      <th class="pb-50">
                        <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                        <span class="font-weight-bold">
                          {{ $t('Status Kapal Sesuai INAPORTNET') }}
                        </span>
                      </th>
                      <td class="pb-50">
                        {{ form.schedule.status_kapal || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                        <span class="font-weight-bold">
                          {{ $t('Nomor RPKRO') }}
                        </span>
                      </th>
                      <td class="pb-50">{{ form.schedule.no_rpkro || '-' }}</td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                        <span class="font-weight-bold">
                          {{ $t('Nomor RKBM') }}
                        </span>
                      </th>
                      <td class="pb-50">
                        {{ form.schedule.no_rkbm || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                        <span class="font-weight-bold">
                          {{ $t('Nomor PBMBB') }}
                        </span>
                      </th>
                      <td class="pb-50">
                        {{ form.schedule.no_pbmbb || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                        <span class="font-weight-bold">
                          {{ $t('Nomor SPOG') }} (Kedatangan)
                        </span>
                      </th>
                      <td class="pb-50">
                        {{ form.schedule.no_spog || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                        <span class="font-weight-bold">
                          {{ $t('Nomor SPOG') }} (Keberangkatan)
                        </span>
                      </th>
                      <td class="pb-50">
                        {{ form.schedule.no_spog_brk || '-' }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <!-- </b-card> -->

              <!-- <b-card
                border-variant="secondary"

              > -->
              <div v-if="form.schedule.jenis_kapal === 'Barge'">
                <div class="divider my-2">
                  <div class="divider-text">
                    <b-badge :variant="'info'">
                      {{ form.tug_boat.name_tug_boat }}
                    </b-badge>
                  </div>
                </div>

                <div class="p-0">
                  <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                      <th class="pb-50">
                        <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                        <span class="font-weight-bold">
                          {{ $t('Status Kapal Sesuai INAPORTNET') }}
                        </span>
                      </th>
                      <td class="pb-50">
                        {{ form.schedule.status_kapal_tb || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                        <span class="font-weight-bold">
                          {{ $t('Nomor RPKRO') }}
                        </span>
                      </th>
                      <td class="pb-50">
                        {{ form.schedule.no_rpkro_tb || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                        <span class="font-weight-bold">
                          {{ $t('Nomor RKBM') }}
                        </span>
                      </th>
                      <td class="pb-50">
                        {{ form.schedule.no_spog_tb || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                        <span class="font-weight-bold">
                          {{ $t('Nomor SPOG') }} (Kedatangan)
                        </span>
                      </th>
                      <td class="pb-50">
                        {{ form.schedule.no_spog_tb || '-' }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                        <span class="font-weight-bold">
                          {{ $t('Nomor SPOG') }} (Keberangkatan)
                        </span>
                      </th>
                      <td class="pb-50">
                        {{ form.schedule.no_spog_tb_brk || '-' }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <!-- </b-card> -->
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span>
              It was a pleasure working with you and your team. We hope you will
              keep us in mind for future shipping. Thank You!
            </span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="3" xl="2" class="invoice-actions" style="">
        <b-card>
          <!-- Button: Send Invoice -->
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
            :disabled="true"
          >
            Send Email
          </b-button> -->

          <!-- Button: DOwnload
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            Download
          </b-button> -->

          <!-- Button: Print -->
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button> -->

          <!-- Button: Edit -->
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :to="{
              name: 'apps-invoice-edit',
              params: { id: $route.params.id }
            }"
          >
            Edit
          </b-button> -->

          <!-- Button: Add Payment -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :to="$router.currentRoute.params.previous_link"
          >
            Kembali
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
            :to="{
              name: 'dashboard-monitoring-rrv-hulu-migas-view',
              params: {
                id: form.form_chain_hm_id,
                api: 'form_chain_hm',
                previous_link: $router.currentRoute
              }
            }"
          >
            Realisasikan
          </b-button>
        </b-card>
        <b-card
          v-if="true"
          style="height: 30em !important"
          class="custom-verification"
        >
          <h3>
            <b-spinner small variant="warning" class="" type="grow" />
            Verification
            <b-spinner small variant="warning" class="" type="grow" />
          </h3>
          <hr />
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="ps-customizer-area scroll-area"
          >
            <ol>
              <b-badge
                :variant="'light-warning'"
                class="d-block"
                v-if="
                  !form.schedule.no_rpkro ||
                  !form.schedule.no_spog ||
                  !form.schedule.kegiatan_start_time
                "
              >
                {{ form.dm_river_boat_hm.boat_name }}
              </b-badge>
              <!-- <li
                v-for="(msg, i) in form.verification_hm.filter(
                  (data) => !data.manual_message.includes('[TB]')
                )"
                :key="i"
              > -->
              <!-- <small>
                  <span v-html="msg.manual_message"></span>
                </small> -->

              <li v-if="form.schedule.status_kapal != 'PT. SAL'">
                <span>{{ $t('Status Kapal Sesuai INAPORTNET') }}</span>
                <feather-icon :icon="'XCircleIcon'" class="ml-25" />
              </li>
              <li v-if="!form.schedule.no_rkbm">
                <span v-html="'Belum mengisi Nomor RKBM'"></span>
                <feather-icon :icon="'XCircleIcon'" class="ml-25" />
              </li>
              <li v-if="!form.schedule.no_pbmbb">
                <span v-html="'Belum mengisi Nomor PBMBB'"></span>
                <feather-icon :icon="'XCircleIcon'" class="ml-25" />
              </li>

              <li v-if="!form.schedule.no_spog">
                <span v-html="'Belum mengisi Nomor SPOG'"></span>
                <feather-icon :icon="'XCircleIcon'" class="ml-25" />
              </li>

              <li v-if="!form.schedule.kegiatan_start_time">
                <span v-html="'Belum mengisi waktu kegiatan'"></span>
                <feather-icon :icon="'XCircleIcon'" class="ml-25" />
              </li>

              <span v-if="form.schedule.jenis_kapal === 'Barge'">
                <!-- show only for tug boat -->
                <b-badge
                  :variant="'light-warning'"
                  class="d-block"
                  v-if="
                    !form.schedule.no_rpkro_tb ||
                    !form.schedule.no_spog_tb ||
                    !form.schedule.kegiatan_start_time_tb
                  "
                >
                  {{ form.tug_boat.name_tug_boat }}
                </b-badge>
                <li v-if="form.schedule.status_kapal_tb != 'PT. SAL'">
                  <span>{{ $t('Status Kapal Sesuai INAPORTNET') }}</span>
                  <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                </li>
                <li v-if="!form.schedule.no_rpkro_tb">
                  <span
                    v-html="
                      'Belum mengisi Nomor RPKRO (Menyetujui boleh mengisi SPOG)'
                    "
                  ></span>
                  <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                </li>
                <li v-if="!form.schedule.no_rkbm_tb">
                  <span v-html="'Belum mengisi Nomor RKBM'"></span>
                  <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                </li>
                <li v-if="!form.schedule.no_pbmbb_tb">
                  <span v-html="'Belum mengisi Nomor PBMBB'"></span>
                  <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                </li>
                <li v-if="!form.schedule.no_spog_tb">
                  <span v-html="'Belum mengisi Nomor SPOG'"></span>
                  <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                </li>
                <li v-if="!form.schedule.kegiatan_start_time_tb">
                  <span v-html="'Belum mengisi waktu kegiatan'"></span>
                  <feather-icon :icon="'XCircleIcon'" class="ml-25" />
                </li>
                <!-- show only for tug boat -->
              </span>
              <!-- show only for admin sal -->
              <!-- </li> -->
            </ol>
          </vue-perfect-scrollbar>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import NestedIO from '@views/pages/components/NestedIO.vue'
import TablePipeLineGood from '@views/pages/monitoring/jalur-sungai/hulu-migas/wizard/table-kegiatan/TablePipeLineGood.vue'
import TableManualHandlingGood from '@views/pages/monitoring/jalur-sungai/hulu-migas/wizard/table-kegiatan/TableManualHandlingGood.vue'
import ChainTimeline from '@views/pages/monitoring/jalur-sungai/hulu-migas/summary/ChainTimeline.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle
  },
  components: {
    VuePerfectScrollbar,
    AppTimeline,
    AppTimelineItem,
    TablePipeLineGood,
    TableManualHandlingGood,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    NestedIO,
    Logo,
    ToastificationContent,
    ChainTimeline
  },
  computed: {
    currentlyKegiatanKapal() {
      return () => {
        let kegiatan = []

        let fhm_keg_pipelines = this.form.schedule.fhm_keg_pipelines
        let fhm_keg_manhs = this.form.schedule.fhm_keg_manhs

        if (fhm_keg_pipelines) {
          fhm_keg_pipelines.forEach((itm) => {
            const { kegiatan_hm } = itm
            kegiatan.push(kegiatan_hm.keg_name)
          })
        }
        if (fhm_keg_manhs) {
          fhm_keg_manhs.forEach((itm) => {
            const { kegiatan_hm } = itm
            kegiatan.push(kegiatan_hm.keg_name)
          })
        }
        if (fhm_keg_pipelines || fhm_keg_manhs) {
          /* eliminasi duplicate here */
          kegiatan = kegiatan.filter(function (value, index, array) {
            return array.indexOf(value) === index
          })
        }
        if (kegiatan.length === 0) {
          kegiatan = 'TAMBAT'
        }
        return kegiatan.toString().replace(/,/g, ', ') || kegiatan
      }
    },
    formStatusVariant() {
      return (any) => this.$variantDDFormStatus[any]
    },
    showVerification() {
      return () => {
        if (this.$can('SAL', '')) {
          const cond =
            !this.form.schedule.no_rpkro ||
            !this.form.schedule.no_spog ||
            !this.form.schedule.kegiatan_start_time
          const cond1 =
            !this.form.schedule.no_rpkro_tb ||
            !this.form.schedule.no_spog_tb ||
            !this.form.schedule.kegiatan_start_time_tb

          if (this.form.schedule.jenis_kapal === 'Barge') {
            return cond || cond1
          } else {
            return cond
          }
        }
        if (this.$can('manage', 'special')) {
          return !this.form.schedule.no_do_bl
        }
        if (this.$can('TBBM', '')) {
          const cond =
            !this.form.schedule.no_rkbm || !this.form.schedule.no_pbmbb
          const cond1 =
            !this.form.schedule.no_rkbm_tb || !this.form.schedule.no_pbmbb_tb
          if (this.form.schedule.jenis_kapal === 'Barge') {
            return cond || cond1
          } else {
            return cond
          }
        }
        return true
      }
    }
  },

  setup() {
    const paymentDetails = {
      totalDue: '$12,110.55',
      bankName: 'American Bank',
      country: 'United States',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905'
    }
    const invoiceDescription = [
      {
        taskTitle: 'Native App Development',
        taskDescription:
          'Developed a full stack native app using React Native, Bootstrap & Python',
        rate: '$60.00',
        hours: '30',
        total: '$1,800.00'
      },
      {
        taskTitle: 'UI Kit Design',
        taskDescription:
          'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
        rate: '$60.00',
        hours: '20',
        total: '$1200.00'
      }
    ]

    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceDescription,
      printInvoice,
      paymentDetails
    }
  },
  data() {
    return {
      BACKEND_URL: process.env.VUE_APP_BACKEND_URL,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false
      },
      total_realisasi_kegiatan: null,
      invoiceData: {
        id: 1,
        issuedDate: '13 Dec 2019',
        client: {
          address: '7777 Mendez Plains',
          company: 'Hall-Robbins PLC',
          companyEmail: 'don85@johnson.com',
          country: 'USA',
          contact: '(616) 865-4180',
          name: 'Jordan Stevenson'
        },
        service: 'Software Development',
        total: 3428,
        avatar: '',
        invoiceStatus: 'Paid',
        balance: '$724',
        dueDate: '23 Apr 2019'
      },
      isHaveFile: null,
      form_realisasi: {
        rka1: null,
        rka2: null,
        keg: null,
        keg_tb: null
      },
      jetty_positions: [],
      jetty_baris_positions: [
        {
          value: 'null',
          text: 'Posisi Baris'
        },
        {
          value: 'Baris 1',
          text: 'Baris 1'
        },
        {
          value: 'Baris 2',
          text: 'Baris 2'
        },
        {
          value: 'Baris 3',
          text: 'Baris 3'
        }
      ],
      form: {
        tujuan_kapal: null,
        verification_hm: [],
        flag_verified: null,
        /* for form chain hm */
        form_chain_hm_id: null,
        form_tug_boat_id: null,
        form_hulu_migas_id: null,

        uri_chain_master: 'form_chain_hm',
        uri_chain_main:
          '' /* already override by form hulu migas in schedule payload */,
        uri_chain_second: 'form_tug_boat',
        /* for form chain hm */

        isSuccessTugBoat: false,
        isTugBoatOnly: false,
        table_mh: 0,
        table_pipe_line: 0,
        table_jetty_position: 0,

        currentModel: '',
        currentFieldSuggest: '',
        currentFieldSuggestChild: null,
        currentFieldHaveChildren: null,

        // isAgenAndTrans: false,
        // showIsAgenAndTrans: false,
        // temp_transportir_id: '',

        // group: '',
        // pemilik: '',
        created_by: '',
        form_hulu_miga: [],
        dm_river_boat_hm: {
          /* data master kapal */ stateRevisi: null,
          updateID: null,
          isEverRun: false,
          uri: 'dm_river_boat_hm/',
          isNew: true,
          boat_name: '',
          gt: null,
          loa: null,
          captain_name: '',
          captain_telp: '',
          // no_pkk: '',
          // created_at: moment(),
          dm_river_boat_hms: [],
          dm_shipping_agent_id: null,
          dm_shipping_agent: {
            updateID: null,
            isEverRun: false,
            uri: 'dm_shipping_agent/',
            isNew: true,
            shipping_name: '',
            dm_shipping_agents: []
          }
        },
        dm_asal_kapal: {
          updateID: null,
          isEverRun: false,
          uri: 'dm_asal_kapal/',
          isNew: true,
          asal: '',
          dm_asal_kapals: []
        },
        tug_boat: {
          updateID: null,
          isEverRun: false,
          uri: 'tug_boat/',
          isNew: true,
          no_pkk_tug_boat: '',
          name_tug_boat: '',
          tug_sandar_start_time: null,
          tug_sandar_end_time: null,
          tug_boats: [],
          gt_tug_boat: null,
          loa_tug_boat: null,
          captain_name_tug_boat: '',
          captain_telp_tug_boat: '',
          // no_pkk: '',
          // created_at: moment(),
          dm_shipping_agent_id: null,
          dm_shipping_agent: {
            updateID: null,
            isEverRun: false,
            uri: 'dm_shipping_agent/',
            isNew: true,
            shipping_name: '',
            dm_shipping_agents: []
          }
        },
        schedule: {
          // api
          uri: 'form_hulu_migas/',
          uriStatus: 'form_hulu_migas/update/status/',
          status_kapal: '',
          rencana_position_jetty: '',
          position_jetty: '',
          rencana_baris_position_jetty: '',
          baris_position_jetty: '',

          jenis_kapal: null,
          tug_boat_id: null,

          // payload
          // kegiatan: '',
          // kegiatan_key: 100,
          rencana_sandar: null,
          keterangan: '',
          kegiatan: '',
          fhm_keg_pipelines: null,
          fhm_keg_manhs: null,

          uuid: uuidv4(),
          no_pkk: null,
          no_pkk_tug_boat: null
        }
      }
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {},
  methods: {
    moment,

    icons(item) {
      console.log('item.type', item.type)
      if (['image/png', 'png'].includes(item.type)) {
        return require('@/assets/images/icons/png.png')
      }
      if (['image/jpeg', 'jpeg', 'jpg'].includes(item.type)) {
        return require('@/assets/images/icons/jpg.png')
      }
      if (['application/pdf'].includes(item.type)) {
        return require('@/assets/images/icons/pdf.png')
      }
      if (['xls'].includes(item.type)) {
        return require('@/assets/images/icons/pdf.png')
      }
    },
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$alert({
            title: `Text copied : ${string}`,
            variant: 'primary',
            icon: 'CheckCircleIcon'
          })
        },
        (e) => {
          this.$alert({
            title: `Can not copy!`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      )
    },
    formatDateTime(value, format = 'D MMMM YYYY - HH:mm') {
      if (!value) {
        return ''
      }
      const data = moment(value).format(format)
      return data
    },
    durationAsString(start, end) {
      const duration = moment.duration(moment(end).diff(moment(start)))

      //Get Days
      const days = Math.floor(duration.asDays()) // .asDays returns float but we are interested in full days only
      const daysFormatted = days ? `${days} days ` : '' // if no full days then do not display it at all

      //Get Hours
      const hours = duration.hours()
      const hoursFormatted = `${hours} hours `

      //Get Minutes
      const minutes = duration.minutes()
      const minutesFormatted = `${minutes} minutes`

      //Get seconds
      var seconds = duration.seconds()
      const secondsFormatted = ` ${seconds} seconds`

      //Get milliseconds
      var milliseconds = moment(end).diff(moment(start))
      const millisecondsFormatted = ` | ${milliseconds} milliseconds`

      return [
        daysFormatted,
        hoursFormatted,
        minutesFormatted,
        secondsFormatted,
        millisecondsFormatted
      ].join('')
    },
    async fetchData() {
      const uriAPI = 'form_chain_hm'
      // const dataID = 1
      const dataID = this.$router.currentRoute.params.id

      if (dataID !== undefined) {
        this.fetchLoading = true

        await useJwt.http
          .get(`${uriAPI}/${dataID}`)
          .then(async (response) => {
            this.fetchLoading = false
            this.form.tug_boat.tug_boats = []

            console.log('fetchData:', response.data[`${uriAPI}s`])
            console.log(`uri api : ${uriAPI}s`)

            let {
              verification_hms,
              form_hulu_miga,
              form_tug_boat,
              flag_verified,
              status,
              id
            } = response.data[`${uriAPI}s`]

            this.form.flag_verified = flag_verified

            this.form.form_chain_hm_id = id
            // this.dataPayloadModalVerification.form_chain_hm_id = id
            this.keyModalVerification += 1
            const origin = form_hulu_miga

            if (!!verification_hms) {
              this.form.verification_hm = []
              verification_hms.forEach((element) => {
                this.form.verification_hm.push({
                  id: element.id,
                  manual_message: element.manual_message,
                  state_ok: element.state_ok
                })
              })
            }

            if (!!form_hulu_miga) {
              const {
                dm_river_boat_hm,
                user,
                dm_asal_kapal,
                realisasi_posisi_kapals,
                fhm_keg_pipelines,
                fhm_keg_manhs
              } = form_hulu_miga
              this.form.schedule.no_spog = form_hulu_miga.no_spog
              this.form.schedule.no_spog_brk = form_hulu_miga.no_spog_brk
              // [ Tujuan Kapal : {{ form?.tujuan_kapal }} ]
              this.form.tujuan_kapal = form_hulu_miga.tujuan_kapal
              this.form.schedule.fhm_keg_pipelines = fhm_keg_pipelines
              this.form.schedule.fhm_keg_manhs = fhm_keg_manhs
              this.form.schedule.no_pkk = form_hulu_miga.no_pkk
              // eslint-disable-next-line camelcase
              const { dm_shipping_agent } = dm_river_boat_hm
              // eslint-disable-next-line camelcase
              this.form.created_by = `${this.userData?.full_name} ( ${
                this.userData?.perusahaan || '-'
              } ${this.userData?.phone} )`

              // // payload association
              this.form.schedule.dm_river_boat_hm_id = dm_river_boat_hm.id

              // // payload
              this.form.schedule.id = origin.id
              this.form.form_hulu_migas_id = origin.id
              console.log(
                'this.form.form_hulu_migas_id:::',
                this.form.form_hulu_migas_id
              )
              this.form.table_jetty_position += 1

              this.form.schedule.jenis_kapal = origin.jenis_kapal

              this.form.schedule.rencana_sandar = origin.rencana_sandar

              this.form.schedule.rencana_position_jetty =
                origin.rencana_position_jetty
              this.form.schedule.rencana_baris_position_jetty =
                origin.rencana_baris_position_jetty
              this.form.schedule.baris_position_jetty =
                origin.baris_position_jetty

              this.form.schedule.created_at = origin.created_at

              this.form.schedule.status_kapal = origin.status_kapal
              this.form.schedule.no_rpkro = origin.no_rpkro
              this.form.schedule.no_rkbm = origin.no_rkbm
              this.form.schedule.no_pbmbb = origin.no_pbmbb

              this.form.schedule.status_kapal_tb = origin.status_kapal_tb
              this.form.schedule.no_rpkro_tb = origin.no_rpkro_tb

              this.form.schedule.no_pindah = origin.no_pindah
              this.form.schedule.ijin_lengkap_time = origin.ijin_lengkap_time

              this.form.schedule.disetujui_time = origin.disetujui_time

              this.form.schedule.position_jetty = origin.position_jetty

              this.form.schedule.sandar_start_time = origin.sandar_start_time
              // this.onStartChange('', origin.sandar_start_time)
              this.form.schedule.sandar_end_time = origin.sandar_end_time

              let nowRKA1 = moment(origin.sandar_start_time)
              let endRKA1 = moment(origin.sandar_end_time) // another date
              this.form_realisasi.rka1 = this.durationAsString(nowRKA1, endRKA1)
              console.log(
                'this.form_realisasi.rka1::',
                this.form_realisasi.rka1
              )

              this.form.schedule.kegiatan_start_time =
                origin.kegiatan_start_time
              this.form.schedule.kegiatan_end_time = origin.kegiatan_end_time
              this.form.schedule.kegiatan_batal_time =
                origin.kegiatan_batal_time

              this.form.schedule.form_status = origin.form_status
              this.form.schedule.kegiatan = origin.kegiatan
              this.form.schedule.is_cancel = origin.is_cancel
              this.form.schedule.updated_at = origin.updated_at

              // helper to not update kegiatan_batal_time
              this.form.schedule.set_kegiatan_batal_time = false

              this.form.form_hulu_miga = form_hulu_miga

              this.form.dm_river_boat_hm = {
                // eslint-disable-next-line camelcase
                ...dm_river_boat_hm,
                updateID: dm_river_boat_hm.id,
                isEverRun: true,
                uri: 'dm_river_boat_hm/',
                isNew: false,
                dm_river_boat_hms: []
              }
              // if (tug_boat !== null) {
              //   this.form.tug_boat = {
              //     // eslint-disable-next-line camelcase
              //     ...tug_boat,
              //     updateID: tug_boat.id,
              //     isEverRun: true,
              //     uri: 'tug_boat/',
              //     isNew: false,
              //     tug_boats: []
              //   }
              // } else {
              //   this.tug_boat = {
              //     ...this.tug_boat
              //   }
              // }

              /* TODO REMOVE */
              if (dm_asal_kapal !== null) {
                console.log('dm_asal_kapal: exist', dm_asal_kapal)
                this.form.schedule.dm_asal_kapal_id = dm_asal_kapal.id
                this.form.dm_asal_kapal = {
                  // eslint-disable-next-line camelcase
                  ...dm_asal_kapal,
                  updateID: dm_asal_kapal.id,
                  isEverRun: true,
                  uri: 'dm_asal_kapal/',
                  isNew: false,
                  dm_asal_kapals: []
                }
              } else {
                console.log('dm_asal_kapal: not exist', dm_asal_kapal)
                /* CREATE BY SAL */
                this.form.dm_asal_kapal = {
                  // eslint-disable-next-line camelcase
                  ...this.form.dm_asal_kapal
                }
              }

              this.form.dm_river_boat_hm.dm_shipping_agent = {
                // eslint-disable-next-line camelcase
                ...dm_shipping_agent,
                updateID: dm_shipping_agent.id,
                isEverRun: true,
                uri: 'dm_shipping_agent/',
                isNew: false,
                dm_shipping_agents: []
              }
            }
            // re render for crud kegiatan
            this.form.table_pipe_line += 1
            this.form.table_mh += 1

            if (!!form_tug_boat) {
              this.form.schedule.no_spog_tb = form_tug_boat.no_spog_tb
              this.form.schedule.no_spog_tb_brk = form_tug_boat.no_spog_tb_brk

              const { tug_boat } = form_tug_boat
              const { dm_shipping_agent } = tug_boat
              this.form.form_tug_boat_id = form_tug_boat.id
              this.form.schedule.no_pkk_tug_boat = form_tug_boat.no_pkk_tug_boat

              let nowRKA2 = moment(form_tug_boat.tug_sandar_start_time)
              let endRKA2 = moment(form_tug_boat.tug_sandar_end_time) // another date
              this.form_realisasi.rka2 = this.durationAsString(nowRKA2, endRKA2)

              this.form.tug_boat = {
                ...this.form.tug_boat,
                ...tug_boat,
                tug_sandar_start_time: form_tug_boat.tug_sandar_start_time,
                tug_sandar_end_time: form_tug_boat.tug_sandar_end_time
              }
              this.form.tug_boat.dm_shipping_agent = {
                // eslint-disable-next-line camelcase
                ...dm_shipping_agent,
                updateID: dm_shipping_agent.id,
                isEverRun: true,
                uri: 'dm_shipping_agent/',
                isNew: false,
                dm_shipping_agents: []
              }
            }

            /* load rkbm document */
            const model_id = this.form.form_chain_hm_id
            const model = 'form_chain_hm'
            this.loadingFileStorage = true
            await useJwt.http
              .get(`file_storage/${model_id}/${model}`)
              .then((response) => {
                const { file_storages } = response.data
                this.isHaveFile = []
                file_storages.forEach((item) => {
                  this.isHaveFile.push({
                    original_name: `${item.original_name}`,
                    size: `${item.size}`,
                    type: item.type,
                    download_link: `${this.BACKEND_URL}${item.path}/${item.unique_name}`
                  })
                })
                this.loadingFileStorage = false
              })
              .catch((error) => {
                this.loadingFileStorage = false
              })

            /* load total berkegiatan */
            await this.fetchTotalBerkegiatans()

            // if (reRender) {
            //   console.log('init payload: reRender:', this.form)
            //   this.formRender += 1
            // } else {
            //   console.log('init payload:', this.form)
            // }
          })
          .catch((err) => {
            this.fetchLoading = false
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error load data ${err}`,
                icon: 'XIcon',
                variant: 'danger'
              }
            })
          })
          .finally(() => {
            this.fetchLoading = false
          })
      }
    },
    msToTime(duration) {
      var x = duration
      var tempTime = moment.duration(x)
      return `${Math.floor(
        tempTime.asDays()
      )} day ${tempTime.hours()} hours ${tempTime.minutes()} minutes ${tempTime.seconds()} seconds | ${x} milliseconds`
    },

    async fetchTotalBerkegiatans() {
      this.loadingTotalKegiatan = true
      await useJwt.http
        .get(`vw_realisasi_kegiatan/${this.form.form_chain_hm_id}`)
        .then((response) => {
          const { vw_realisasi_kegiatans } = response.data
          const result = this.msToTime(vw_realisasi_kegiatans)
          this.total_realisasi_kegiatan = result
          this.loadingTotalKegiatan = false
        })
        .catch((error) => {
          this.loadingTotalKegiatan = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
.ps-customizer-area {
  height: calc(100% - 83px);
}
.invoice-preview-wrapper {
  .row {
    .invoice-actions {
      .custom-verification {
        &:hover {
          transition: all 0.3s;
          box-shadow: 4px 4px 4px darkblue;
          scale: 1.2;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.invoice-preview-wrapper {
  .invoice-actions {
    position: fixed !important;
    right: 0 !important;
    @media (min-width: 375px) and (max-width: 768px) {
      position: inherit !important;
    }
  }
}
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
