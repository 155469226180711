var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "form-modal",
      "title": _vm.title,
      "body-class": "py-2",
      "size": "lg",
      "scrollable": "",
      "hide-footer": ""
    },
    model: {
      value: _vm.modalShow,
      callback: function callback($$v) {
        _vm.modalShow = $$v;
      },
      expression: "modalShow"
    }
  }, [!_vm.loadingFetch ? _c('b-card-text', [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.onSubmit);
            },
            "reset": function reset($event) {
              $event.preventDefault();
              return _vm.resetForm.apply(null, arguments);
            }
          }
        }, [_c('div', {
          staticClass: "divider my-2"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_c('small', [_vm._v(_vm._s(_vm.highLight.desc4.label) + " " + _vm._s(_vm.payloadModel[_vm.highLight.desc4.model]))]), _c('h3', [_vm._v(" " + _vm._s(_vm.payloadModel[_vm.highLight.title] === 1 ? 'Bongkar' : _vm.payloadModel[_vm.highLight.title] === 2 ? 'Muat' : _vm.payloadModel[_vm.highLight.title]) + " " + _vm._s(_vm.payloadModel[_vm.highLight.title_next]) + " " + _vm._s(_vm.payloadModel[_vm.highLight.desc2.model]) + " " + _vm._s(_vm.payloadModel[_vm.highLight.desc3.model]) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.highLight.desc1.label) + ": " + _vm._s(_vm.highLight.desc1.format === 'time' ? _vm.dateHumanReadAble(_vm.payloadModel[_vm.highLight.desc1.model]) : _vm.payloadModel[_vm.highLight.desc1.model]) + " ")])])]), _vm._l(_vm.compColumnRepeat, function (item, index) {
          return _c('b-row', {
            key: item.fe_id,
            ref: "rowColumnRepeat",
            refInFor: true,
            staticClass: "repeater-form",
            attrs: {
              "id": item.fe_id
            }
          }, [_c('b-col', {
            attrs: {
              "cols": "1"
            }
          }, [_c('b-badge', {
            staticClass: "mt-0",
            attrs: {
              "pill": "",
              "variant": "info"
            }
          }, [_vm._v(" " + _vm._s(index + 1) + " ")])], 1), _c('b-col', {
            attrs: {
              "cols": "10"
            }
          }, [_c('div', {
            staticClass: "divider my-2"
          }, [_c('div', {
            staticClass: "divider-text"
          }, [_c('h4', [_vm._v("Realisasi")]), _c('span', [_vm._v(_vm._s(_vm.durationAsString(item.pip_time_start_rl, item.pip_time_end_rl)))])])])]), _c('b-col', {
            attrs: {
              "cols": "1"
            }
          }, [_c('b-badge', {
            attrs: {
              "pill": "",
              "variant": "danger"
            }
          }, [_c('feather-icon', {
            style: {
              cursor: 'pointer'
            },
            attrs: {
              "icon": "XIcon"
            },
            on: {
              "click": function click($event) {
                return _vm.removeItemColumnRepeat(index);
              }
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "md": "4"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Volume Realisasi *",
              "vid": "Volume Realisasi *",
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "label": "Volume Realisasi *",
                    "label-for": "Volume Realisasi *"
                  }
                }, [_c('b-input-group', [_c('b-form-input', {
                  staticClass: "form-control",
                  attrs: {
                    "id": "Volume Realisasi *",
                    "type": "number",
                    "placeholder": "Volume Realisasi *",
                    "state": _vm.getValidationState(validationContext),
                    "trim": ""
                  },
                  model: {
                    value: item.pip_volume_rl,
                    callback: function callback($$v) {
                      _vm.$set(item, "pip_volume_rl", $$v);
                    },
                    expression: "item.pip_volume_rl"
                  }
                }), _c('b-input-group-append', [_c('v-select', {
                  ref: "select",
                  refInFor: true,
                  attrs: {
                    "id": "pilihan",
                    "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                    "placeholder": "Satuan",
                    "reduce": function reduce(_ref2) {
                      var value = _ref2.value;
                      return value;
                    },
                    "options": [{
                      label: "".concat(_vm.payloadModel[_vm.highLight.desc3.model]),
                      value: "".concat(_vm.payloadModel[_vm.highLight.desc3.model])
                    }],
                    "state": _vm.getValidationState(validationContext)
                  },
                  on: {
                    "search": function search(keyword, loading) {
                      return _vm.fetchOptions(keyword, loading);
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "search",
                    fn: function fn(_ref3) {
                      var attributes = _ref3.attributes,
                        events = _ref3.events;
                      return [_c('input', _vm._g(_vm._b({
                        staticClass: "vs__search",
                        attrs: {
                          "required": !item.pip_satuan_rl,
                          "placeholder": "Satuan"
                        }
                      }, 'input', attributes, false), events))];
                    }
                  }], null, true),
                  model: {
                    value: item.pip_satuan_rl,
                    callback: function callback($$v) {
                      _vm.$set(item, "pip_satuan_rl", $$v);
                    },
                    expression: "item.pip_satuan_rl"
                  }
                }, [_c('span', {
                  attrs: {
                    "slot": "no-options"
                  },
                  slot: "no-options"
                }, [_vm._v(_vm._s(_vm.$t('no_data')))])])], 1)], 1), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1), _c('b-col', {
            attrs: {
              "md": "4"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Jumlah Tenaga Kerja *",
              "vid": "Jumlah Tenaga Kerja *",
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "label": "Jumlah Tenaga Kerja *",
                    "label-for": "Jumlah Tenaga Kerja *"
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "id": "Jumlah Tenaga Kerja *",
                    "type": "number",
                    "placeholder": "Jumlah Tenaga Kerja *",
                    "state": _vm.getValidationState(validationContext),
                    "trim": ""
                  },
                  model: {
                    value: item.pip_jml_tng_krj_rl,
                    callback: function callback($$v) {
                      _vm.$set(item, "pip_jml_tng_krj_rl", $$v);
                    },
                    expression: "item.pip_jml_tng_krj_rl"
                  }
                }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1), _c('b-col', {
            attrs: {
              "md": "4"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Equiptment *",
              "vid": "Equiptment *",
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "label": "Equiptment *",
                    "label-for": "Equiptment *"
                  }
                }, [_c('v-select', {
                  ref: "select",
                  refInFor: true,
                  attrs: {
                    "id": "Equiptment *",
                    "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                    "options": [{
                      title: 'PIPELINE'
                    }],
                    "reduce": function reduce(option) {
                      return option.title;
                    },
                    "state": _vm.getValidationState(validationContext),
                    "label": "title",
                    "disabled": true,
                    "placeholder": "Equiptment *"
                  },
                  on: {
                    "search": function search(keyword, loading) {
                      return _vm.fetchOptions(keyword, loading);
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "search",
                    fn: function fn(_ref4) {
                      var attributes = _ref4.attributes,
                        events = _ref4.events;
                      return [_c('input', _vm._g(_vm._b({
                        staticClass: "vs__search",
                        attrs: {
                          "required": !item.pip_equiptment_rl
                        }
                      }, 'input', attributes, false), events))];
                    }
                  }], null, true),
                  model: {
                    value: item.pip_equiptment_rl,
                    callback: function callback($$v) {
                      _vm.$set(item, "pip_equiptment_rl", $$v);
                    },
                    expression: "item.pip_equiptment_rl"
                  }
                }, [_c('span', {
                  attrs: {
                    "slot": "no-options"
                  },
                  slot: "no-options"
                }, [_vm._v(_vm._s(_vm.$t('no_data')))])]), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1), _c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Waktu Mulai Berkegiatan *",
              "vid": "Waktu Mulai Berkegiatan *",
              "rules": {
                required: true
              }
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "label": "Waktu Mulai Berkegiatan *",
                    "label-for": "Waktu Mulai Berkegiatan *"
                  }
                }, [_c('flat-pickr', {
                  staticClass: "form-control",
                  attrs: {
                    "id": "Waktu Mulai Berkegiatan *",
                    "placeholder": "Waktu Mulai Berkegiatan *",
                    "static": "true",
                    "config": _vm.dpconfig,
                    "state": _vm.getValidationState(validationContext)
                  },
                  model: {
                    value: item.pip_time_start_rl,
                    callback: function callback($$v) {
                      _vm.$set(item, "pip_time_start_rl", $$v);
                    },
                    expression: "item.pip_time_start_rl"
                  }
                }), _c('small', {
                  staticClass: "text-danger"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1), _c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Waktu Selesai Berkegiatan *",
              "vid": "Waktu Selesai Berkegiatan *",
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "label": "Waktu Selesai Berkegiatan *",
                    "label-for": "Waktu Selesai Berkegiatan *"
                  }
                }, [_c('flat-pickr', {
                  staticClass: "form-control",
                  attrs: {
                    "id": "Waktu Selesai Berkegiatan *",
                    "placeholder": "Waktu Selesai Berkegiatan *",
                    "static": "true",
                    "config": _vm.dpconfig,
                    "state": _vm.getValidationState(validationContext)
                  },
                  model: {
                    value: item.pip_time_end_rl,
                    callback: function callback($$v) {
                      _vm.$set(item, "pip_time_end_rl", $$v);
                    },
                    expression: "item.pip_time_end_rl"
                  }
                }), _c('small', {
                  staticClass: "text-danger"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1)], 1);
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "lg": "12",
            "md": "12",
            "cols": "12"
          }
        }, [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "size": "md",
            "variant": "outline-primary",
            "block": ""
          },
          on: {
            "click": _vm.repeatAgainColumn
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "PlusIcon"
          }
        }), _c('span', [_vm._v("Tambah Realisasi Kegiatan")])], 1)], 1)], 1), _c('div', {
          staticClass: "text-right"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": function click($event) {
              _vm.modalShow = false;
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XCircleIcon"
          }
        }), _c('span', [_vm._v(" Close")])], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "type": "submit",
            "variant": "success"
          },
          on: {
            "click": _vm.onSubmitClick
          }
        }, [!_vm.loadingOK ? _c('feather-icon', {
          attrs: {
            "icon": "SaveIcon"
          }
        }) : _vm._e(), _vm.loadingOK ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(_vm.buttonOkTitle)
          }
        })], 1)], 1)], 2)];
      }
    }], null, false, 2018038586)
  })], 1) : _c('b-card-text', [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading. . . ")], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }