<template>
  <app-timeline>
    <!-- v-if="index === 0" -->
    <app-timeline-item
      v-for="(item, index) in response"
      :id="item.id"
      :key="item.id"
      :title="`${item.form_tug_boat.tug_boat.name_tug_boat} ( ${item.form_tug_boat.tug_boat.no_pkk_tug_boat} )`"
      :subtitle="`${item.description}[ ${item.form_chain_hm.form_hulu_miga.dm_river_boat_hm.boat_name} , FORM ID : ${item.form_chain_hm_id} ]`"
      :time="dateHumanReadAble(item.created_at)"
      :variant="variant(index)"
    />
  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'

export default {
  components: {
    AppTimeline,
    AppTimelineItem
  },
  props: {
    bargeOrTug: {
      type: String,
      required: true
    },
    dataID: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      response: []
    }
  },
  computed: {
    variant() {
      return (any) => {
        if (any === 0) return 'info'
        if (any === 1) return 'warning'
        if (any > 1) return 'success'
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    moment,
    dateHumanReadAble(value) {
      const format = moment(value).format('D MMMM YYYY - hh:mm A')
      if (format === 'Invalid date') {
        return 'Belum Diisi ❗️'
      }
      return format
    },
    async fetchData() {
      await useJwt.http
        .get(`history_tug_boat/${this.dataID}/${this.bargeOrTug}`)
        .then((response) => {
          this.response = response.data.history_tug_boats
          console.log(this.response)
        })
    }
  }
}
</script>

<style></style>
