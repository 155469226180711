var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "form-modal",
      "title": _vm.title,
      "body-class": "py-2",
      "size": "lg",
      "hide-footer": ""
    },
    model: {
      value: _vm.modalShow,
      callback: function callback($$v) {
        _vm.modalShow = $$v;
      },
      expression: "modalShow"
    }
  }, [!_vm.loadingFetch ? _c('b-card-text', [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.onSubmit);
            },
            "reset": function reset($event) {
              $event.preventDefault();
              return _vm.resetForm.apply(null, arguments);
            }
          }
        }, [_c('b-row', _vm._l(_vm.column, function (col, index) {
          return _c('b-col', {
            key: index,
            attrs: {
              "md": col.config.md
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": col.label,
              "vid": col.label,
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  key: "".concat(col.label),
                  attrs: {
                    "label": col.label === 'Alamat' ? _vm.customLabelAddress || 'Asal Barang / Tujuan Barang' : col.label,
                    "label-for": col.label === 'Alamat' ? _vm.customLabelAddress || 'Asal Barang / Tujuan Barang' : col.label
                  }
                }, ['input' in col && col.input.type == 'select' ? _c('v-select', {
                  ref: "select",
                  refInFor: true,
                  attrs: {
                    "id": "pilihan",
                    "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                    "placeholder": col.label,
                    "options": col.input.options,
                    "reduce": col.input.reduce,
                    "state": _vm.getValidationState(validationContext)
                  },
                  on: {
                    "search": function search(keyword, loading) {
                      return _vm.fetchOptions(keyword, loading);
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "search",
                    fn: function fn(_ref2) {
                      var attributes = _ref2.attributes,
                        events = _ref2.events;
                      return [_c('input', _vm._g(_vm._b({
                        staticClass: "vs__search",
                        attrs: {
                          "required": !_vm.payloadModel[col.vModel]
                        }
                      }, 'input', attributes, false), events))];
                    }
                  }], null, true),
                  model: {
                    value: _vm.payloadModel[col.vModel],
                    callback: function callback($$v) {
                      _vm.$set(_vm.payloadModel, col.vModel, $$v);
                    },
                    expression: "payloadModel[col.vModel]"
                  }
                }, [_c('span', {
                  attrs: {
                    "slot": "no-options"
                  },
                  slot: "no-options"
                }, [_vm._v(_vm._s(_vm.$t('no_data')))])]) : 'input' in col && col.input.type == 'time' ? _c('flat-pickr', {
                  staticClass: "form-control",
                  attrs: {
                    "id": col.label,
                    "placeholder": col.label,
                    "static": "true",
                    "config": _vm.dpconfig,
                    "state": _vm.getValidationState(validationContext)
                  },
                  model: {
                    value: _vm.payloadModel[col.vModel],
                    callback: function callback($$v) {
                      _vm.$set(_vm.payloadModel, col.vModel, $$v);
                    },
                    expression: "payloadModel[col.vModel]"
                  }
                }) : 'input' in col && col.input.type == 'multiple-input' ? _c('b-input-group', [_c('b-form-input', {
                  attrs: {
                    "id": col.label,
                    "type": "number",
                    "placeholder": col.label,
                    "state": _vm.getValidationState(validationContext),
                    "trim": ""
                  },
                  model: {
                    value: _vm.payloadModel[col.vModel],
                    callback: function callback($$v) {
                      _vm.$set(_vm.payloadModel, col.vModel, $$v);
                    },
                    expression: "payloadModel[col.vModel]"
                  }
                }), _c('b-input-group-append', [_c('v-select', {
                  ref: "select",
                  refInFor: true,
                  attrs: {
                    "id": "pilihan",
                    "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                    "placeholder": "Satuan",
                    "options": col.input.input.options,
                    "reduce": col.input.input.reduce,
                    "state": _vm.getValidationState(validationContext)
                  },
                  on: {
                    "search": function search(keyword, loading) {
                      return _vm.fetchOptions(keyword, loading);
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "search",
                    fn: function fn(_ref3) {
                      var attributes = _ref3.attributes,
                        events = _ref3.events;
                      return [_c('input', _vm._g(_vm._b({
                        staticClass: "vs__search",
                        attrs: {
                          "required": !_vm.payloadModel[col.input.vModel],
                          "placeholder": "Satuan"
                        }
                      }, 'input', attributes, false), events))];
                    }
                  }], null, true),
                  model: {
                    value: _vm.payloadModel[col.input.vModel],
                    callback: function callback($$v) {
                      _vm.$set(_vm.payloadModel, col.input.vModel, $$v);
                    },
                    expression: "payloadModel[col.input.vModel]"
                  }
                }, [_c('span', {
                  attrs: {
                    "slot": "no-options"
                  },
                  slot: "no-options"
                }, [_vm._v(_vm._s(_vm.$t('no_data')))])])], 1)], 1) : 'input' in col && col.input.type == 'number' ? _c('b-form-input', {
                  attrs: {
                    "id": col.label,
                    "type": "number",
                    "placeholder": col.label,
                    "state": _vm.getValidationState(validationContext),
                    "trim": ""
                  },
                  model: {
                    value: _vm.payloadModel[col.vModel],
                    callback: function callback($$v) {
                      _vm.$set(_vm.payloadModel, col.vModel, $$v);
                    },
                    expression: "payloadModel[col.vModel]"
                  }
                }) : _c('b-form-input', {
                  attrs: {
                    "id": col.label,
                    "type": "text",
                    "placeholder": col.label === 'Alamat' ? _vm.customLabelAddress || 'Asal/Tujuan' : col.label,
                    "state": _vm.getValidationState(validationContext),
                    "trim": ""
                  },
                  model: {
                    value: _vm.payloadModel[col.vModel],
                    callback: function callback($$v) {
                      _vm.$set(_vm.payloadModel, col.vModel, $$v);
                    },
                    expression: "payloadModel[col.vModel]"
                  }
                }), 'input' in col && col.input.type == 'time' ? _c('small', {
                  staticClass: "text-danger"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")]) : _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1);
        }), 1), _c('div', {
          staticClass: "text-right"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "secondary",
            "type": "reset"
          },
          on: {
            "click": function click($event) {
              _vm.modalShow = false;
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XCircleIcon"
          }
        }), _c('span', [_vm._v(" Close")])], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "type": "submit",
            "variant": _vm.variantButtonOK
          }
        }, [!_vm.isLoadingOK ? _c('feather-icon', {
          attrs: {
            "icon": "SaveIcon"
          }
        }) : _vm._e(), _vm.isLoadingOK ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(_vm.buttonOK)
          }
        })], 1)], 1)], 1)];
      }
    }], null, false, 157642181)
  })], 1) : _c('b-card-text', [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading. . . ")], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }