var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    staticClass: "d-inline-block w-100",
    attrs: {
      "show": _vm.loading,
      "rounded": "",
      "opacity": "0.6",
      "spinner-small": "",
      "spinner-type": "grow",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('b-icon', {
          attrs: {
            "icon": "stopwatch",
            "font-scale": "3",
            "variant": "info",
            "animation": "cylon"
          }
        }), _c('p', {
          attrs: {
            "id": "cancel-label"
          }
        }, [_vm._v(" Loading... ")])], 1)];
      },
      proxy: true
    }])
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }