<template>
  <div>
    <b-modal
      id="form-modal"
      :title="title"
      v-model="modalShow"
      body-class="py-2"
      size="lg"
      scrollable
      hide-footer
    >
      <b-card-text v-if="!loadingFetch">
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <div class="divider my-2">
              <div class="divider-text">
                <!-- <b-badge :variant="'info'">RK</b-badge> -->
                <small
                  >{{ highLight.desc4.label }}
                  {{ payloadModel[highLight.desc4.model] }}</small
                >
                <h3>
                  {{
                    payloadModel[highLight.title] === 1
                      ? 'Bongkar'
                      : payloadModel[highLight.title] === 2
                      ? 'Muat'
                      : payloadModel[highLight.title]
                  }}
                  {{ payloadModel[highLight.title_next] }}
                  {{ payloadModel[highLight.desc2.model] }}
                  {{ payloadModel[highLight.desc3.model] }}
                </h3>
                <div>
                  <!-- description -->
                  {{ highLight.desc1.label }}:
                  {{
                    highLight.desc1.format === 'time'
                      ? dateHumanReadAble(payloadModel[highLight.desc1.model])
                      : payloadModel[highLight.desc1.model]
                  }}
                </div>
              </div>
            </div>

            <b-row
              v-for="(item, index) in compColumnRepeat"
              :id="item.fe_id"
              :key="item.fe_id"
              ref="rowColumnRepeat"
              class="repeater-form"
            >
              <b-col cols="1">
                <b-badge pill variant="info" class="mt-0">
                  {{ index + 1 }}
                </b-badge>
              </b-col>
              <b-col cols="10">
                <div class="divider my-2">
                  <div class="divider-text">
                    <h4>Realisasi</h4>
                    <span>{{
                      durationAsString(
                        item.pip_time_start_rl,
                        item.pip_time_end_rl
                      )
                    }}</span>
                  </div>
                </div>
              </b-col>
              <b-col cols="1">
                <b-badge pill variant="danger">
                  <feather-icon
                    icon="XIcon"
                    :style="{ cursor: 'pointer' }"
                    @click="removeItemColumnRepeat(index)"
                  />
                </b-badge>
              </b-col>

              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  name="Volume Realisasi *"
                  vid="Volume Realisasi *"
                  rules="required"
                >
                  <b-form-group
                    label="Volume Realisasi *"
                    label-for="Volume Realisasi *"
                  >
                    <b-input-group>
                      <b-form-input
                        class="form-control"
                        id="Volume Realisasi *"
                        v-model="item.pip_volume_rl"
                        type="number"
                        placeholder="Volume Realisasi *"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-input-group-append>
                        <!-- {
                              label: 'M3',
                              value: 'M3'
                            },
                            {
                              label: 'TON',
                              value: 'TON'
                            },
                            {
                              label: 'KL',
                              value: 'KL'
                            } -->
                        <v-select
                          id="pilihan"
                          ref="select"
                          v-model="item.pip_satuan_rl"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :placeholder="`Satuan`"
                          :reduce="({ value }) => value"
                          :options="[
                            {
                              label: `${payloadModel[highLight.desc3.model]}`,
                              value: `${payloadModel[highLight.desc3.model]}`
                            }
                          ]"
                          :state="getValidationState(validationContext)"
                          @search="
                            (keyword, loading) => fetchOptions(keyword, loading)
                          "
                        >
                          <template #search="{ attributes, events }">
                            <input
                              class="vs__search"
                              :required="!item.pip_satuan_rl"
                              placeholder="Satuan"
                              v-bind="attributes"
                              v-on="events"
                            />
                          </template>
                          <span slot="no-options">{{ $t('no_data') }}</span>
                        </v-select>
                        <!-- <b-dropdown
                          :text="
                            specifyCompColumnRepeat(index).pip_satuan_rl ||
                            'Satuan'
                          "
                          variant="outline-primary"
                          :key="reRenderSatuan"
                          right
                        >
                          <b-dropdown-item
                            @click.prevent="updateSatuan('TON', index)"
                            >TON</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click.prevent="updateSatuan('M3', index)"
                            >M3</b-dropdown-item
                          >
                          <b-dropdown-item
                            @click.prevent="updateSatuan('KL', index)"
                            >KL</b-dropdown-item
                          >
                        </b-dropdown> -->
                      </b-input-group-append>
                    </b-input-group>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  name="Jumlah Tenaga Kerja *"
                  vid="Jumlah Tenaga Kerja *"
                  rules="required"
                >
                  <b-form-group
                    label="Jumlah Tenaga Kerja *"
                    label-for="Jumlah Tenaga Kerja *"
                  >
                    <b-form-input
                      id="Jumlah Tenaga Kerja *"
                      v-model="item.pip_jml_tng_krj_rl"
                      type="number"
                      placeholder="Jumlah Tenaga Kerja *"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  name="Equiptment *"
                  vid="Equiptment *"
                  rules="required"
                >
                  <b-form-group label="Equiptment *" label-for="Equiptment *">
                    <v-select
                      id="Equiptment *"
                      ref="select"
                      v-model="item.pip_equiptment_rl"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="[{ title: 'PIPELINE' }]"
                      :reduce="(option) => option.title"
                      :state="getValidationState(validationContext)"
                      label="title"
                      :disabled="true"
                      placeholder="Equiptment *"
                      @search="
                        (keyword, loading) => fetchOptions(keyword, loading)
                      "
                    >
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :required="!item.pip_equiptment_rl"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                      <span slot="no-options">{{ $t('no_data') }}</span>
                    </v-select>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  name="Waktu Mulai Berkegiatan *"
                  vid="Waktu Mulai Berkegiatan *"
                  :rules="{
                    required: true
                  }"
                >
                  <b-form-group
                    label="Waktu Mulai Berkegiatan *"
                    label-for="Waktu Mulai Berkegiatan *"
                  >
                    <flat-pickr
                      id="Waktu Mulai Berkegiatan *"
                      v-model="item.pip_time_start_rl"
                      placeholder="Waktu Mulai Berkegiatan *"
                      class="form-control"
                      static="true"
                      :config="dpconfig"
                      :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  name="Waktu Selesai Berkegiatan *"
                  vid="Waktu Selesai Berkegiatan *"
                  rules="required"
                >
                  <b-form-group
                    label="Waktu Selesai Berkegiatan *"
                    label-for="Waktu Selesai Berkegiatan *"
                  >
                    <flat-pickr
                      id="Waktu Selesai Berkegiatan *"
                      v-model="item.pip_time_end_rl"
                      placeholder="Waktu Selesai Berkegiatan *"
                      class="form-control"
                      static="true"
                      :config="dpconfig"
                      :state="getValidationState(validationContext)"
                    />

                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- END OF LOOPING FIELD -->
            <b-row>
              <b-col lg="12" md="12" cols="12">
                <b-button
                  size="md"
                  variant="outline-primary"
                  block
                  class="mb-2"
                  @click="repeatAgainColumn"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>Tambah Realisasi Kegiatan</span>
                </b-button>
              </b-col>
            </b-row>
            <!-- Save User -->
            <div class="text-right">
              <!-- type="reset" -->
              <b-button
                variant="secondary"
                class="mr-1"
                @click="modalShow = false"
              >
                <feather-icon icon="XCircleIcon" />
                <span> Close</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                @click="onSubmitClick"
              >
                <feather-icon v-if="!loadingOK" icon="SaveIcon" />

                <b-spinner v-if="loadingOK" small />
                <span v-text="buttonOkTitle"></span>
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
      <b-card-text v-else> <b-spinner small /> Loading. . . </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment'
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BCardText,
  BTable,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput,
  BButton,
  BPagination,
  BFormSelect,
  BModal,
  BDropdown,
  BDropdownItem,
  BLink,
  BFormInvalidFeedback
} from 'bootstrap-vue'
import VSelect from 'vue-select'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { ref, watch } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import VDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import { VueAutosuggest } from 'vue-autosuggest'
import useJwt from '@/auth/jwt/useJwt'
import OverlayLoading from '@/views/components/OverlayLoading.vue'
import '@core/scss/vue/libs/vue-autosuggest.scss'
import { t } from '@/@core/libs/i18n/utils'

export default {
  components: {
    VueAutosuggest,
    Indonesian,
    flatPickr,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BCardText,
    BTable,
    BLink,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BDropdown,
    BModal,
    ValidationObserver,
    ValidationProvider,
    VSelect,
    OverlayLoading,
    VDropzone
  },
  props: {
    uri: {
      type: String,
      required: true
    },
    uriSecond: {
      type: String,
      required: true
    },
    formChainHmID: {
      type: Number,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: true
    },
    isModalActive: {
      type: Boolean,
      required: true
    },
    payload: {
      type: [Object, Array],
      required: true
    },
    column: {
      type: [Object, Array],
      required: true
    },
    columnRepeat: {
      type: [Object, Array],
      required: true
    },
    highLight: {
      type: [Object, Array],
      required: true
    },
    action: {
      type: String,
      required: false
    },
    dataID: {
      type: String,
      required: false
    },
    secondID: {
      type: String,
      required: false
    },
    buttonOkTitle: {
      type: String,
      required: false
    },
    resolveFetchDetail: {
      type: Function,
      required: false
    },
    resolveFetchDetailSecond: {
      type: Function,
      required: false
    }
  },
  directives: {
    Ripple
  },
  data() {
    return {
      required,
      reRenderSatuan: 111,
      dataResetDebounce: null,
      dataColumnRepeat: null,
      repeatFlagNumber: 1,
      customLabelAddress: null,
      loadingOK: false,
      loadingFetch: false,
      dpconfig: {
        // default
        // wrap: true,
        // altInput: true,
        // locale: Indonesian,
        // defaultDate: 'today',
        wrap: true,
        enableTime: true,
        time_24hr: true,
        altInput: true,
        altFormat: 'j F Y - H:i',
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: 'today',
        // allowInput: false // prevent "readonly" prop
        parseDate: (datestr, format) => {
          return 'asd'
        }
        // formatDate: (date, format, locale) => {
        //   // locale can also be used
        //   return moment(date).format(format)
        // }
      }
    }
  },
  watch: {
    isModalActive: function () {
      console.log('action:: ', this.action)

      if (this.action === 'is_edit' || this.action === 'is_realisasikan') {
        /* fetch data */
        this.fetchDetail()
      } else {
        this.resetForm()
      }
    },

    'payloadModel.kegiatan_hm_id': function () {
      console.log(
        'this.payloadModel kegiatan_hm_id',
        this.payloadModel.kegiatan_hm_id
      )
      const label = this.payloadModel.kegiatan_hm_id === 1 ? 'Asal' : 'Tujuan'
      this.customLabelAddress = label
    }
  },
  setup(props, { emit, attrs, slots, expose }) {
    const blankPayload = props.payload /* ARRAY */
    const strBlankPayload = JSON.stringify(blankPayload)

    const payloadModel = ref(JSON.parse(strBlankPayload))
    const resetPayload = () => {
      payloadModel.value = JSON.parse(strBlankPayload)
    }
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetPayload)

    let resetDebounce = null

    const postJWT = (payload) => {
      console.log('is_add', payload)
      useJwt.http
        .post(props.uri, payload)
        .then((response) => {
          /* clear form input || resetForm */
          if (resetDebounce) clearTimeout(resetDebounce)
          resetDebounce = setTimeout(() => resetPayload(), 300)
          emit('closeModal', false, response.data.message) // emit('update:is-add-new-modal-active', false)
        })
        .catch((error) => {
          emit('closeModal', true, error) // emit('update:is-add-new-modal-active', false)
        })
    }
    const putJWT = (payload) => {
      console.log('is_edit', payload)
      useJwt.http
        .put(`${props.uri}/high_light/${props.dataID}`, payload)
        .then((response) => {
          /* clear form input || resetForm */
          if (resetDebounce) clearTimeout(resetDebounce)
          resetDebounce = setTimeout(() => resetPayload, 300)
          emit('closeModal', false, response.data.message) // emit('update:is-add-new-modal-active', false)
        })
        .catch((error) => {
          emit('closeModal', true, error) // emit('update:is-add-new-modal-active', false)
        })
    }

    const onSubmit = () => {
      // console.log('asd')
      // /* DTO */
      // if (props.action === 'is_add') {
      //   // postJWT(payloadModel.value)
      // } else {
      // console.log('props.columnRepeat::', props.columnRepeat)
      //   // putJWT(payloadModel.value)
      // }
    }

    return {
      payloadModel,
      onSubmit,
      resetForm,

      refFormObserver,
      getValidationState
    }
  },
  computed: {
    modalShow: {
      get() {
        return this.isModalActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeModal')
        }
      }
    },
    specifyCompColumnRepeat() {
      return (index) => this.compColumnRepeat[index]
    },
    compColumnRepeat: {
      get() {
        return this.dataColumnRepeat
      },
      set(data) {
        this.dataColumnRepeat = data
      }
    },
    variantButtonOK: {
      get() {
        const mapLabel = {
          is_add: 'success',
          is_edit: 'primary',
          is_realisasikan: 'success'
        }
        return mapLabel[this.action] || 'success'
      }
    },
    buttonOK: {
      get() {
        const mapLabel = {
          is_add: ' Tambah Data',
          is_edit: ' Simpan Perubahan',
          is_realisasikan: ' Simpan Realisasi'
        }
        return mapLabel[this.action] || ' Submit'
      }
    }
  },
  created() {},
  methods: {
    moment,
    updateSatuan(satuan, index) {
      this.reRenderSatuan += 1
      this.compColumnRepeat[index].pip_satuan_rl = satuan
    },
    onSubmitClick() {
      this.loadingOK = true
      this.$refs.refFormObserver
        .validate()
        .then(async (success) => {
          if (success) {
            /* DTO */
            if (this.action === 'is_add') {
              // postJWT(payloadModel.value)
            } else {
              useJwt.http
                .post(
                  `${this.uriSecond}/${this.secondID}/${this.formChainHmID}`,
                  {
                    bulk_rel_keg_pipeline: this.dataColumnRepeat
                  }
                )
                .then((response) => {
                  /* clear form input || resetForm */
                  // if (this.dataResetDebounce) clearTimeout(this.dataResetDebounce)
                  // this.dataResetDebounce = setTimeout(() => this.resetForm(), 300)
                  if (this.dataResetDebounce)
                    clearTimeout(this.dataResetDebounce)
                  this.dataResetDebounce = setTimeout(() => {
                    this.loadingOK = false
                    this.$emit('closeModal', false, response.data.message)
                  }, 300)
                })
                .catch((error) => {
                  if (this.dataResetDebounce)
                    clearTimeout(this.dataResetDebounce)
                  this.dataResetDebounce = setTimeout(() => {
                    this.loadingOK = false
                    this.$emit('closeModal', true, error)
                  }, 300)
                })
            }
          } else {
            if (this.dataResetDebounce) clearTimeout(this.dataResetDebounce)
            this.dataResetDebounce = setTimeout(() => {
              this.loadingOK = false
            }, 300)
          }
        })
        .catch((error) => {
          console.log(error)
          if (this.dataResetDebounce) clearTimeout(this.dataResetDebounce)
          this.dataResetDebounce = setTimeout(() => {
            this.loadingOK = false
          }, 300)
        })
    },
    durationAsString(start, end) {
      const duration = moment.duration(moment(end).diff(moment(start)))

      //Get Days
      const days = Math.floor(duration.asDays()) // .asDays returns float but we are interested in full days only
      const daysFormatted = days ? `${days} days ` : '' // if no full days then do not display it at all

      //Get Hours
      const hours = duration.hours()
      const hoursFormatted = `${hours} hours `

      //Get Minutes
      const minutes = duration.minutes()
      const minutesFormatted = `${minutes} minutes`

      //Get seconds
      var seconds = duration.seconds()
      const secondsFormatted = ` ${seconds} seconds`

      //Get milliseconds
      var milliseconds = moment(end).diff(moment(start))
      const millisecondsFormatted = ` | ${milliseconds} milliseconds`

      return [
        daysFormatted,
        hoursFormatted,
        minutesFormatted,
        secondsFormatted,
        millisecondsFormatted
      ].join('')
    },
    dateHumanReadAble(value) {
      const format = moment(value).format('D MMMM YYYY - hh:mm A')
      if (format === 'Invalid date') {
        return 'Belum Diisi ❗️'
      }
      return format
    },
    fetchDetail() {
      this.loadingFetch = true
      useJwt.http
        .get(`${this.uri}/${this.dataID}`)
        .then((res) => {
          const data = this.resolveFetchDetail(res)
          this.payloadModel = data
          this.loadingFetch = false
        })
        .catch((e) => {
          console.log('error', e)
          this.$alert({
            title: `Error`,
            text: `Something wrong ${e}`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
          this.loadingFetch = false
        })

      if (this.secondID) {
        useJwt.http
          .get(`${this.uriSecond}/${this.secondID}`)
          .then((res) => {
            console.log(res)
            const data = this.resolveFetchDetailSecond(res)
            this.compColumnRepeat = []
            if (data) {
              this.compColumnRepeat = data.map((item) => ({
                fe_id: item.id,
                pip_equiptment_rl: 'PIPELINE',
                pip_satuan_rl: this.payloadModel[this.highLight.desc3.model],
                asd: moment(item.pip_time_start_rl).format('YYYY-MM-DD hh:mm'),
                pip_time_start_rl: moment(item.pip_time_start_rl).format(
                  'YYYY-MM-DD hh:mm'
                ),
                pip_time_end_rl: moment(item.pip_time_end_rl).format(
                  'YYYY-MM-DD hh:mm'
                ),
                ...item
              }))
              console.log('this.compColumnRepeat:::', this.compColumnRepeat)
            }
            this.loadingFetch = false
          })
          .catch((e) => {
            console.log('error', e)
            this.$alert({
              title: `Error`,
              text: `Something wrong ${e}`,
              variant: 'danger',
              icon: 'XCircleIcon'
            })
            this.loadingFetch = false
          })
      }
    },
    repeatAgainColumn() {
      // this.columnRepeat.push([])
      // this.columnRepeat.push(...this.columnRepeat)
      this.dataColumnRepeat.push({
        fe_id: (this.repeatFlagNumber += this.repeatFlagNumber),
        pip_equiptment_rl: 'PIPELINE',
        pip_satuan_rl: this.payloadModel[this.highLight.desc3.model]
      })

      // this.$nextTick(() => {
      //   this.trAddHeight(this.$refs.rowColumnRepeat[0].offsetHeight)
      // })
    },
    removeItemColumnRepeat(index) {
      this.dataColumnRepeat.splice(index, 1)
      // this.trTrimHeight(this.$refs.rowColumnRepeat[0].offsetHeight)
    },
    //
    getSuggestionValue(suggestion) {
      this.form.add.group_id = suggestion.item.id
      this.form.add.group_name = suggestion.item.name
      return suggestion.item.name
    },
    getSuggestionValueEdit(suggestion) {
      this.form.edit.group_id = suggestion.item.id
      this.form.edit.group_name = suggestion.item.name
      console.log(suggestion.item.name)
      return suggestion.item.name
    }
  }
}
</script>
<style>
table#table-transition .flip-list-move {
  transition: transform 0.4s;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
