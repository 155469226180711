<template>
  <div>
    <b-modal
      id="form-modal"
      :title="title"
      v-model="modalShow"
      body-class="py-2"
      size="lg"
      hide-footer
    >
      <b-card-text v-if="!loadingFetch">
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col
                v-for="(col, index) in column"
                :key="index"
                :md="col.config.md"
              >
                <!-- normal input -->
                <validation-provider
                  #default="validationContext"
                  :name="col.label"
                  :vid="col.label"
                  rules="required"
                >
                  <b-form-group
                    :key="`${col.label}`"
                    :label="
                      col.label === 'Alamat'
                        ? customLabelAddress || 'Asal Barang / Tujuan Barang'
                        : col.label
                    "
                    :label-for="
                      col.label === 'Alamat'
                        ? customLabelAddress || 'Asal Barang / Tujuan Barang'
                        : col.label
                    "
                  >
                    <v-select
                      v-if="'input' in col && col.input.type == 'select'"
                      id="pilihan"
                      ref="select"
                      v-model="payloadModel[col.vModel]"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :placeholder="col.label"
                      :options="col.input.options"
                      :reduce="col.input.reduce"
                      :state="getValidationState(validationContext)"
                      @search="
                        (keyword, loading) => fetchOptions(keyword, loading)
                      "
                    >
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :required="!payloadModel[col.vModel]"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                      <span slot="no-options">{{ $t('no_data') }}</span>
                    </v-select>
                    <!-- :value="beforeSentDate(data)" -->
                    <!-- :allowinput="data.field.input.disabled ? 'allowinput' : ''" -->
                    <flat-pickr
                      v-else-if="'input' in col && col.input.type == 'time'"
                      :id="col.label"
                      v-model="payloadModel[col.vModel]"
                      :placeholder="col.label"
                      class="form-control"
                      static="true"
                      :config="dpconfig"
                      :state="getValidationState(validationContext)"
                    />
                    <b-input-group
                      v-else-if="
                        'input' in col && col.input.type == 'multiple-input'
                      "
                    >
                      <b-form-input
                        :id="col.label"
                        v-model="payloadModel[col.vModel]"
                        type="number"
                        :placeholder="col.label"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                      <b-input-group-append>
                        <v-select
                          id="pilihan"
                          ref="select"
                          v-model="payloadModel[col.input.vModel]"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          placeholder="Satuan"
                          :options="col.input.input.options"
                          :reduce="col.input.input.reduce"
                          :state="getValidationState(validationContext)"
                          @search="
                            (keyword, loading) => fetchOptions(keyword, loading)
                          "
                        >
                          <template #search="{ attributes, events }">
                            <input
                              class="vs__search"
                              :required="!payloadModel[col.input.vModel]"
                              placeholder="Satuan"
                              v-bind="attributes"
                              v-on="events"
                            />
                          </template>
                          <span slot="no-options">{{ $t('no_data') }}</span>
                        </v-select>
                        <!-- <b-dropdown
                          :text="payloadModel[col.input.vModel] || 'Satuan'"
                          variant="outline-primary"
                          :state="getValidationState(validationContext)"
                          :key="reRenderSatuan"
                          right
                        >
                          <b-dropdown-item
                            v-for="(dr, index2) in col.input.input.options"
                            :key="index2"
                            @click.prevent="
                              {
                                reRenderSatuan += 1
                                payloadModel[col.input.vModel] = dr.label
                              }
                            "
                            >{{ dr.label }}</b-dropdown-item
                          >
                        </b-dropdown> -->
                      </b-input-group-append>
                    </b-input-group>
                    <b-form-input
                      v-else-if="'input' in col && col.input.type == 'number'"
                      :id="col.label"
                      v-model="payloadModel[col.vModel]"
                      type="number"
                      :placeholder="col.label"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                    <b-form-input
                      v-else
                      :id="col.label"
                      v-model="payloadModel[col.vModel]"
                      type="text"
                      :placeholder="
                        col.label === 'Alamat'
                          ? customLabelAddress || 'Asal/Tujuan'
                          : col.label
                      "
                      :state="getValidationState(validationContext)"
                      trim
                    />
                    <small
                      v-if="'input' in col && col.input.type == 'time'"
                      class="text-danger"
                    >
                      {{ validationContext.errors[0] }}
                    </small>
                    <b-form-invalid-feedback v-else>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- Save User -->
            <div class="text-right">
              <b-button
                variant="secondary"
                class="mr-1"
                type="reset"
                @click="modalShow = false"
              >
                <feather-icon icon="XCircleIcon" />
                <span> Close</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                :variant="variantButtonOK"
              >
                <feather-icon v-if="!isLoadingOK" icon="SaveIcon" />
                <b-spinner v-if="isLoadingOK" small />
                <span v-text="buttonOK"></span>
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
      <b-card-text v-else> <b-spinner small /> Loading. . . </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BCardText,
  BTable,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BPagination,
  BFormSelect,
  BModal,
  BDropdown,
  BDropdownItem,
  BLink,
  BFormInvalidFeedback
} from 'bootstrap-vue'
import VSelect from 'vue-select'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { ref, watch, computed } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import VDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import { VueAutosuggest } from 'vue-autosuggest'
import useJwt from '@/auth/jwt/useJwt'
import OverlayLoading from '@/views/components/OverlayLoading.vue'
import '@core/scss/vue/libs/vue-autosuggest.scss'
import { t } from '@/@core/libs/i18n/utils'

export default {
  components: {
    VueAutosuggest,
    Indonesian,
    flatPickr,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BCardText,
    BTable,
    BLink,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BDropdown,
    BModal,
    ValidationObserver,
    ValidationProvider,
    VSelect,
    OverlayLoading,
    VDropzone
  },
  props: {
    uri: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    isModalActive: {
      type: Boolean,
      required: true
    },
    payload: {
      type: [Object, Array],
      required: true
    },
    column: {
      type: [Object, Array],
      required: true
    },
    action: {
      type: String,
      required: false
    },
    dataID: {
      type: String,
      required: false
    },
    resolveFetchDetail: {
      type: Function,
      required: false
    }
  },
  directives: {
    Ripple
  },
  data() {
    return {
      required,
      reRenderSatuan: 111,
      loadingFetch: false,
      customLabelAddress: null,
      dpconfig: {
        wrap: true,
        enableTime: true,
        time_24hr: true,
        altInput: true,
        altFormat: 'j F Y - H:i',
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      }
    }
  },
  watch: {
    isModalActive: function () {
      console.log('action:: ', this.action)
      console.log('payloadModel:: ', this.payloadModel)
      console.log('dataID:: ', this.dataID)
      if (this.action === 'is_edit') {
        /* fetch data */
        this.fetchDetail()
      } else {
        this.resetForm()
      }
    },
    'payloadModel.kegiatan_hm_id': function () {
      console.log(
        'this.payloadModel kegiatan_hm_id',
        this.payloadModel.kegiatan_hm_id
      )
      const label =
        this.payloadModel.kegiatan_hm_id === 1 ? 'Asal Barang' : 'Tujuan Barang'
      this.customLabelAddress = label
    }
  },
  setup(props, { emit, attrs, slots, expose }) {
    let isLoadingOK = ref(false)
    const loadingOK = () => (isLoadingOK.value = !isLoadingOK.value)

    const blankPayload = props.payload /* ARRAY */
    const strBlankPayload = JSON.stringify(blankPayload)

    const payloadModel = ref(JSON.parse(strBlankPayload))
    const resetPayload = () => {
      payloadModel.value = JSON.parse(strBlankPayload)
    }
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetPayload)

    let resetDebounce = null

    const postJWT = (payload) => {
      console.log('is_add', payload)
      useJwt.http
        .post(props.uri, payload)
        .then((response) => {
          /* clear form input || resetForm */
          if (resetDebounce) clearTimeout(resetDebounce)
          resetDebounce = setTimeout(() => {
            loadingOK()
            resetPayload()
          }, 300)
          emit('closeModal', false, response.data.message) // emit('update:is-add-new-modal-active', false)
        })
        .catch((error) => {
          loadingOK()
          emit('closeModal', true, error) // emit('update:is-add-new-modal-active', false)
        })
    }
    const putJWT = (payload) => {
      console.log('is_edit', payload)
      useJwt.http
        .put(`${props.uri}/${props.dataID}`, payload)
        .then((response) => {
          /* clear form input || resetForm */
          if (resetDebounce) clearTimeout(resetDebounce)
          resetDebounce = setTimeout(() => {
            loadingOK()
            resetPayload()
          }, 300)
          emit('closeModal', false, response.data.message) // emit('update:is-add-new-modal-active', false)
        })
        .catch((error) => {
          loadingOK()
          emit('closeModal', true, error) // emit('update:is-add-new-modal-active', false)
        })
    }

    const onSubmit = () => {
      /* DTO */
      loadingOK()
      if (props.action === 'is_add') {
        postJWT(payloadModel.value)
      } else {
        putJWT(payloadModel.value)
      }
    }

    return {
      payloadModel,
      onSubmit,
      resetForm,
      isLoadingOK: computed(() => isLoadingOK.value),

      refFormObserver,
      getValidationState
    }
  },
  computed: {
    modalShow: {
      get() {
        return this.isModalActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeModal')
        }
      }
    },
    specifyColumn() {
      return (index) => this.column[index]
    },
    variantButtonOK: {
      get() {
        const mapLabel = {
          is_add: 'success',
          is_edit: 'primary'
        }
        return mapLabel[this.action] || 'success'
      }
    },
    buttonOK: {
      get() {
        const mapLabel = {
          is_add: ' Tambah Data',
          is_edit: ' Simpan Perubahan'
        }
        return mapLabel[this.action] || ' Submit'
      }
    }
  },
  created() {},
  methods: {
    fetchDetail() {
      this.loadingFetch = true
      useJwt.http
        .get(`${this.uri}/${this.dataID}`)
        .then((res) => {
          const data = this.resolveFetchDetail(res)
          this.payloadModel = data
          this.loadingFetch = false
        })
        .catch((e) => {
          console.log('error', e)
          modalShow = false
          this.loadingFetch = false
          this.$alert({
            title: `Error`,
            text: `Something wrong ${e}`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        })
    },
    getSuggestionValue(suggestion) {
      this.form.add.group_id = suggestion.item.id
      this.form.add.group_name = suggestion.item.name
      return suggestion.item.name
    },
    getSuggestionValueEdit(suggestion) {
      this.form.edit.group_id = suggestion.item.id
      this.form.edit.group_name = suggestion.item.name
      console.log(suggestion.item.name)
      return suggestion.item.name
    }
  }
}
</script>
<style>
table#table-transition .flip-list-move {
  transition: transform 0.4s;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
