<template>
  <b-overlay
    :show="loading"
    rounded
    opacity="0.6"
    spinner-small
    spinner-type="grow"
    spinner-variant="primary"
    class="d-inline-block w-100"
  >
    <slot />
    <template #overlay>
      <div class="text-center">
        <b-icon
          icon="stopwatch"
          font-scale="3"
          variant="info"
          animation="cylon"
        />
        <p id="cancel-label">
          Loading...
        </p>
        <!-- <b-button
          ref="cancel"
          variant="outline-danger"
          size="sm"
          aria-describedby="cancel-label"
          @click="loading = false"
        >
          Cancel
        </b-button> -->
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { BOverlay, BIcon, BIconStopwatch } from 'bootstrap-vue'

export default {
  name: 'OverlayLoading',
  components: {
    BOverlay,
    BIcon,
    BIconStopwatch,
  },
  props: {
    loading: Boolean,
  },
  data() {
    return {}
  },
}
</script>
