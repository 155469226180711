<template>
  <div class="monitoring">
    <FormModal
      :key="`ModalRNFhmMH${keyModalRNFhmMH}`"
      uri="/fhm_keg_manh"
      title="Form Rencana Kegiatan (Manual Handling)"
      :is-modal-active.sync="dataModalRNFhmMH"
      :payload="dataPayloadModalRNFhmMH"
      :column="columnModalRNFhmMH"
      :action="operationModalRNFhmMH"
      :data-i-d="`${dataIDModalRNFhmMH}`"
      :resolve-fetch-detail="methodModalRNFhmMHResolveFetchDetail"
      @closeModal="methodModalRNFhmMH"
    />
    <FormModalMH
      :key="`ModalHGRNFhmMH${keyModalHGRNFhmMH}`"
      uri="/fhm_keg_manh"
      uriSecond="/rel_keg_mh"
      title="Form Realisasi Kegiatan (Manual Handling)"
      :is-modal-active.sync="dataModalHGRNFhmMH"
      :payload="dataPayloadModalRNFhmMH"
      :column="columnModalHGRNFhmMH"
      :columnRepeat="columnModalHGRepeateRNFhmMH"
      :high-light="highLightModalHGRNFhmMH"
      :action="operationModalRNFhmMH"
      :data-i-d="`${dataIDModalRNFhmMH}`"
      :second-i-d="`${dataIDModalRNFhmMHOnly}`"
      :form-chain-hm-i-d="formChainHmID"
      :resolve-fetch-detail="methodModalRNFhmMHResolveFetchDetail"
      :resolve-fetch-detail-second="methodModalRNFhmMHResolveFetchDetailSecond"
      button-ok-title=" Simpan Realisasi"
      @closeModal="methodModalHGRNFhmMH"
    />
    <b-row v-if="!isRealisasi">
      <b-col md="6" cols="12">
        <b-form-group>
          <div class="d-flex align-items-center">
            <!-- <b-form-input
              v-model="searchTerm"
              placeholder="Cari data"
              type="text"
              class="d-inline-block"
            /> -->
          </div>
        </b-form-group>
      </b-col>
      <b-col class="text-right mb-2" md="6" cols="12">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-button
            v-if="
              $store.state.app.roleName !== 'viewer' &&
              $store.state.app.roleName !== 'jetty man'
            "
            id="tambah-data"
            class="button-tambah mr-1"
            @click="methodModalRNFhmMH(true, null, 'is_add')"
            :variant="'success'"
            :disabled="disableCrudX"
          >
            <!-- @click="newData(status)" -->
            <span
              ><strong>
                <feather-icon icon="FilePlusIcon" />

                Tambah Data
              </strong></span
            >
          </b-button>
          <b-button
            id="export-data"
            class="button-export mr-1"
            :variant="'warning'"
            @click="exportDataTable(selectedRows)"
          >
            <!-- @click="newData(status)" -->
            <span
              ><strong>
                <feather-icon icon="FilePlusIcon" />

                Export to Excel (
                {{
                  selectedRows.length === 0 ? 'ALL Rows' : `Only Row Selected`
                }}
                <b-badge v-if="selectedRows.length !== 0">
                  {{ selectedRows.length }}
                </b-badge>
                )
              </strong></span
            >
          </b-button>
        </div>
      </b-col>
    </b-row>
    <div v-if="yo_isLoading" class="progress-wrapper">
      <b-card-text class="mb-0">
        Memuat data… {{ yo_isLoadingValue + '%' }}
      </b-card-text>
      <b-progress
        :key="yo_isLoadingValue"
        animated
        :value="yo_isLoadingValue"
        variant="info"
        max="100"
        :class="'progress-bar-info'"
      />
    </div>

    <!-- table -->
    <vue-good-table
      :key="yoRender"
      class="yo-good-table"
      mode="remote"
      :total-rows="total_records"
      :columns="isRealisasi ? realisasiColumns : columns"
      :rows="rows"
      :is-loading.sync="yo_isLoading"
      :rtl="direction"
      :search-options="{
        enabled: true,
        skipDiacritics: true,
        placeholder: 'Search this table'
      }"
      @on-search="onSearchTable"
      :select-options="{
        enabled: !isRealisasi,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'Rows Selected',
        clearSelectionText: 'Clear',
        disableSelectInfo: false, // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: serverParams.perPage,
        mode: 'pages',
        position: 'bottom',
        // perPageDropdown: [3, 7, 9],
        // dropdownAllowAll: false,
        // setCurrentPage: 1,
        jumpFirstOrLast: true,
        // firstLabel : 'First Page'
        // lastLabel : 'Last Page'
        // nextLabel: 'next',
        // prevLabel: 'prev',
        // rowsPerPageLabel: 'Rows per page',
        // ofLabel: 'of',
        // pageLabel: 'page', // for 'pages' mode
        // allLabel: 'All',
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`
      }"
      :group-options="{
        enabled: true
      }"
      compact-mode
      theme="polar-bear"
      style-class="vgt-table bordered"
      :line-numbers="true"
      :fixed-header="false"
      max-height="700px"
      @on-selected-rows-change="selectionChanged"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <!--
         -->
      <template slot="table-header-row" slot-scope="props">
        Kegiatan
        <BBadge variant="primary" class="mr-1">
          <strong>{{ props.row.label }}</strong>
        </BBadge>
        <BBadge variant="secondary">
          Jumlah Data : {{ props.row.children.length }}
        </BBadge>
      </template>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Status -->
        <span v-if="props.column.field === 'mh_no_pbmbb'">
          <strong>
            {{ props.row.mh_no_pbmbb }}
            <br />
            <span
              v-text="
                `${
                  props.row.kegiatan_hm.keg_name === 'BONGKAR'
                    ? 'Asal: '
                    : 'Tujuan: '
                }${props.row.mh_address}`
              "
            ></span>
          </strong>
        </span>
        <span v-else-if="props.column.field === 'mh_rencana_keg'">
          <strong>
            <div class="divider my-2">
              <div class="divider-text">
                <div>
                  {{ dateHumanReadAble(props.row.mh_rencana_keg) }}
                </div>
                <small>Signatured: {{ props.row.user.full_name }}</small>
              </div>
            </div>
          </strong>
        </span>
        <span v-else-if="props.column.field === 'rencana_desc'">
          <div class="divider my-2">
            <div class="divider-text">
              <!-- <b-badge :variant="'info'">RK</b-badge> -->
              <small>Segment {{ props.row.mh_pemilik_brg }}</small>
              <h3>
                {{ props.row.mh_name_barang }}
                {{ props.row.mh_volume }}
                {{ props.row.mh_satuan }}
              </h3>
              <div>
                <!-- description -->
                Direncanakan <br />
                {{ dateHumanReadAble(props.row.mh_rencana_keg) }}
              </div>
              <hr />
              <small>Signatured <br />{{ props.row.user.full_name }}</small>
            </div>
          </div>
        </span>
        <span v-else-if="props.column.field === 'realisasi_timeline'">
          <app-timeline v-if="!!props.row.rel_keg_mhs.length">
            <app-timeline-item
              v-for="(item, index) in props.row.rel_keg_mhs"
              :title="`Sebanyak ${item.mh_volume_rl} ${item.mh_satuan_rl} ( Menggunakan ${item.mh_equiptment_rl} )`"
              :key="index"
              :subtitle="`JML Tenaga Kerja ${item.mh_jml_tng_krj_rl} <br/>`"
              icon="ClockIcon"
              time="Selama 1 day 1 hours 1 minutes"
              variant="success"
            >
              <div
                class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
              >
                <h6>
                  {{ `Sebanyak ${item.mh_volume_rl} ${item.mh_satuan_rl}` }}
                </h6>
                <small class="text-muted">03:00 PM</small>
              </div>
              <p>
                Kegiatan dimulai pada tanggal
                <strong>
                  {{ `${dateHumanReadAble(item.mh_time_start_rl)}` }}
                </strong>
                <br />
                sampai
                <strong>
                  {{ `${dateHumanReadAble(item.mh_time_end_rl)}` }}
                </strong>
                <!-- Develop an online store of electronic devices for the provided
                layout, as well as develop a mobile version of it. The must be
                compatible with any CMS. -->
              </p>
              <div
                class="d-flex flex-sm-row flex-column justify-content-between align-items-start"
              >
                <!-- 1st Col -->
                <div class="mb-1 mb-sm-0">
                  <small
                    ><span class="text-muted mb-50 d-block"
                      >JML Tenaga Kerja</span
                    ></small
                  >

                  <div>
                    <b-avatar
                      :text="item.mh_jml_tng_krj_rl"
                      class="mr-50"
                      size="24"
                      variant="light-primary"
                    />
                    <!-- <b-avatar
                      text="B"
                      class="mr-50"
                      size="24"
                      variant="light-success"
                    />
                    <b-avatar
                      text="C"
                      class="mr-50"
                      size="24"
                      variant="light-danger"
                    /> -->
                  </div>
                </div>
                <!-- 2nd Col -->
                <div class="mb-1 mb-sm-0">
                  <small
                    ><span class="text-muted mb-50 d-block">Equiptment</span>
                  </small>
                  <small
                    ><span>{{ `${item.mh_equiptment_rl}` }}</span>
                  </small>
                </div>
                <!-- 3rd Col -->
                <div>
                  <small
                    ><span class="text-muted mb-50 d-block">Total Waktu</span>
                  </small>
                  <small>
                    <span>
                      {{
                        `${durationAsString(
                          item.mh_time_start_rl,
                          item.mh_time_end_rl
                        )}`
                      }}
                    </span>
                  </small>
                </div>
              </div>
            </app-timeline-item>

            <!-- <app-timeline-item
              title="Your are running low on time"
              subtitle="Only 30 minutes left to finish milestone"
              icon="ClockIcon"
              time="21 minutes ago"
              variant="warning"
            /> -->
          </app-timeline>
          <app-timeline v-else>
            <app-timeline-item
              title="Belum melakukan realisasi kegiatan"
              subtitle="You are just one step away from your goal"
              icon="InfoIcon"
              time="a minutes ago"
              variant="danger"
            />
          </app-timeline>
        </span>
        <span v-else-if="props.column.field === 'mh_name_barang'">
          <strong>
            <span>{{ props.row.mh_name_barang }}</span>
            <br />
            <span
              >Jumlah Unit: {{ props.row.mh_volume }}
              {{ props.row.mh_satuan }}</span
            >
            <br />
            <span
              v-text="
                `${
                  props.row.kegiatan_hm.keg_name === 'BONGKAR'
                    ? 'Asal: '
                    : 'Tujuan: '
                }${props.row.mh_address}`
              "
            />
          </strong>
          <!-- {{ dateHumanReadAble(props.row.rencana_sandar) }}
          <feather-icon
            class="cursor-pointer mr-1"
            :icon="
              dateHumanReadAble(props.row.rencana_sandar).includes('AM')
                ? 'SunIcon'
                : 'MoonIcon'
            "
          /> -->
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span v-if="$store.state.app.roleName !== 'viewer'">
            <span v-if="permissionKegiatan(props.row.user_id)">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="30"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  variant="primary"
                  toggle-class="p-0"
                  no-caret
                  @click="
                    methodModalRNFhmMH(true, null, 'is_edit', props.row.id)
                  "
                >
                  <feather-icon
                    class="mr-50 cursor-pointer"
                    icon="PenToolIcon"
                    size="20"
                  />
                  <span>Ubah Rencana Kegiatan</span>
                </b-dropdown-item>
                <b-dropdown-item
                  variant="success"
                  toggle-class="p-0"
                  no-caret
                  @click="
                    methodModalHGRNFhmMH(true, null, 'is_edit', props.row.id)
                  "
                >
                  <feather-icon
                    class="mr-50 cursor-pointer"
                    icon="AnchorIcon"
                    size="20"
                  />
                  <span>Realisasikan Kegiatan</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item variant="danger" toggle-class="p-0" no-caret>
                <feather-icon
                  class="mr-50 cursor-pointer"
                  icon="TrashIcon"
                  size="20"
                />
                <span>Delete</span>
              </b-dropdown-item> -->
              </b-dropdown>
            </span>
            <span v-else>
              <b-alert variant="primary" show class="mb-0">
                <div class="alert-body">No Access</div>
              </b-alert>
            </span>
          </span>
          <br />
          <span v-if="props.row.rel_keg_mhs.length">
            Sudah direalisasikan
            {{ checkRealisasi(props.row.rel_keg_mhs) }}
            {{ props.row.mh_satuan }}
          </span>
          <span v-else>
            <span>
              <b-alert variant="warning" show class="mb-0">
                <div class="alert-body">Belum direalisasikan</div>
              </b-alert>
            </span>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          <strong>{{ props.formattedRow[props.column.field] }}</strong>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { getUserData } from '@/auth/utils'
import FormModal from '@views/pages/components/modal/FormModal.vue'
import FormModalMH from '@views/pages/components/modal/FormModalMH.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    FormModal,
    FormModalMH,
    VSelect,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BCardActions,
    flatPickr
  },
  props: {
    fhmID: {
      type: Number,
      required: false,
      default: null
    },
    formChainHmID: {
      type: Number,
      required: false,
      default: null
    },
    isRealisasi: {
      type: Boolean,
      required: false,
      default: null
    },
    disableCrud: {
      type: String,
      required: false,
      default: null
    },
    dataHighLight: {
      type: [Object, Array],
      required: false,
      default: null
    }
  },
  setup() {
    const { isVerticalMenuCollapsed, anIncomingNotification } = useAppConfig()

    // const isCollapse = computed(() => isCollapse.value === true)
    // const triggerFCM = computed(() => triggerFCM.value >= 1)

    return {
      isVerticalMenuCollapsed /* Fixed Header !false Good Table #issue debounce 300s */,
      // isCollapse,
      anIncomingNotification /* FirebaseFCM */
    }
  },
  data() {
    return {
      userID: JSON.parse(localStorage.getItem('userData')).id,
      /* Modal Create Rencana Kegiatan Manual Handling */
      keyModalHGRNFhmMH: 1,
      dataModalHGRNFhmMH: false,
      disableCrudMutation: this.disableCrud,
      highLightModalHGRNFhmMH: {
        /* v-model */
        title: 'kegiatan_hm_id',
        title_next: 'mh_name_barang',
        desc1: {
          label: 'Rencana Kegiatan',
          model: 'mh_rencana_keg',
          format: 'time'
        },
        desc2: {
          label: 'Jumlah Unit',
          model: 'mh_volume',
          format: 'none'
        },
        desc3: {
          label: 'Satuan',
          model: 'mh_satuan',
          format: 'none'
        },
        desc4: {
          label: 'Segment',
          model: 'mh_pemilik_brg',
          format: 'none'
        },
        desc5: {
          label: 'Address',
          model: 'mh_address',
          format: 'none'
        }
      },
      columnModalHGRepeateRNFhmMH: [
        {
          fe_id: 1
        }
        // [
        //   {
        //     label: 'Volume Realisasi *',
        //     vModel: 'mh_volume_rel',
        //     config: {
        //       md: 4,
        //       required: true
        //     },
        //     input: {
        //       type: 'number'
        //     }
        //   },
        //   {
        //     label: 'Satuan Realisasi*',
        //     vModel: 'mh_satuan_rel',
        //     config: {
        //       md: 4,
        //       required: true
        //     },
        //     input: {
        //       type: 'select',
        //       options: [
        //         {
        //           label: 'M3',
        //           value: 'M3'
        //         },
        //         {
        //           label: 'TON',
        //           value: 'TON'
        //         }
        //       ],
        //       reduce({ value }) {
        //         return value
        //       }
        //     }
        //   },
        //   {
        //     label: 'Jumlah Tenaga Kerja *',
        //     vModel: 'mh_jml_tng_krj',
        //     config: {
        //       md: 4,
        //       required: true
        //     },
        //     input: {
        //       type: 'number'
        //     }
        //   },
        //   {
        //     label: 'Equiptment *',
        //     vModel: 'mh_equiptment',
        //     config: {
        //       md: 4,
        //       required: true
        //     },
        //     input: {
        //       type: 'form-input'
        //     }
        //   },
        //   {
        //     label: 'Kegiatan Start *',
        //     vModel: 'mh_rencana_keg_start',
        //     config: {
        //       md: 4,
        //       required: true
        //     },
        //     input: {
        //       type: 'time'
        //     }
        //   },
        //   {
        //     label: 'Kegiatan End *',
        //     vModel: 'mh_rencana_keg_end',
        //     config: {
        //       md: 4,
        //       required: true
        //     },
        //     input: {
        //       type: 'time'
        //     }
        //   }
        // ]
      ],
      columnModalHGRNFhmMH: [
        {
          label: 'Jumlah Unit Realisasi *',
          vModel: 'mh_volume_rel',
          config: {
            md: 4,
            required: true
          },
          input: {
            type: 'number'
          }
        },
        {
          label: 'Satuan Realisasi*',
          vModel: 'mh_satuan_rel',
          config: {
            md: 4,
            required: true
          },
          input: {
            type: 'select',
            options: [
              {
                label: 'PALLET',
                value: 'PALLET'
              },
              {
                label: 'PCS',
                value: 'PCS'
              },
              {
                label: 'UNIT',
                value: 'UNIT'
              },
              {
                label: 'TOTE TANK',
                value: 'TOTE TANK'
              },
              {
                label: 'BIG BAG',
                value: 'BIG BAG'
              }
            ],
            reduce({ value }) {
              return value
            }
          }
        },
        {
          label: 'Jumlah Tenaga Kerja *',
          vModel: 'mh_jml_tng_krj',
          config: {
            md: 4,
            required: true
          },
          input: {
            type: 'number'
          }
        },
        {
          label: 'Equiptment *',
          vModel: 'mh_equiptment',
          config: {
            md: 4,
            required: true
          },
          input: {
            type: 'form-input'
          }
        },
        {
          label: 'Kegiatan Start *',
          vModel: 'mh_rencana_keg_start',
          config: {
            md: 4,
            required: true
          },
          input: {
            type: 'time'
          }
        },
        {
          label: 'Kegiatan End *',
          vModel: 'mh_rencana_keg_end',
          config: {
            md: 4,
            required: true
          },
          input: {
            type: 'time'
          }
        }
      ],
      //

      keyModalRNFhmMH: 1,
      dataModalRNFhmMH: false,
      dataPayloadModalRNFhmMH: {
        /* ID_DATA */
        fhm_keg_manh_id: null,

        kegiatan_hm_id: null,
        mh_name_barang: null,
        mh_volume: null,
        mh_satuan: null,
        mh_pemilik_brg: null,
        mh_address: null,
        mh_rencana_keg: null,
        /* default payload */
        form_hulu_migas_id: null
      },
      operationModalRNFhmMH: null,
      dataIDModalRNFhmMH: null,
      dataIDModalRNFhmMHOnly: null,
      columnModalRNFhmMH: [
        {
          label: 'Kegiatan *',
          vModel: 'kegiatan_hm_id',
          config: {
            md: 12
          },
          input: {
            type: 'select',
            options: [
              {
                label: 'BONGKAR',
                value: 1
              },
              {
                label: 'MUAT',
                value: 2
              }
            ],
            reduce({ value }) {
              return value
            }
          }
        },
        {
          label: 'Nama Barang *',
          vModel: 'mh_name_barang',
          config: {
            md: 6
          },
          input: {
            type: 'form-input'
          }
        },
        {
          label: 'Jumlah Unit *',
          vModel: 'mh_volume',
          config: {
            md: 6
          },
          input: {
            type: 'multiple-input',
            vModel: 'mh_satuan',
            input: {
              options: [
                {
                  label: 'PALLET',
                  value: 'PALLET'
                },
                {
                  label: 'PCS',
                  value: 'PCS'
                },
                {
                  label: 'UNIT',
                  value: 'UNIT'
                },
                {
                  label: 'TOTE TANK',
                  value: 'TOTE TANK'
                },
                {
                  label: 'BIG BAG',
                  value: 'BIG BAG'
                }
              ],
              reduce({ value }) {
                return value
              }
            }
          }
        },
        {
          label: 'Segment *',
          vModel: 'mh_pemilik_brg',
          config: {
            md: 6
          },
          input: {
            type: 'select',
            options: [
              {
                label: 'LMP',
                value: 'LMP'
              },
              {
                label: 'CEMENTING',
                value: 'CEMENTING'
              },
              {
                label: 'COIL TUBIN',
                value: 'COIL TUBIN'
              },
              {
                label: 'WIRELINE',
                value: 'WIRELINE'
              },
              {
                label: 'STIMULATION',
                value: 'STIMULATION'
              },
              {
                label: 'OTHERS',
                value: 'OTHERS'
              }
            ],
            reduce({ value }) {
              return value
            }
          }
        },
        {
          label: 'Alamat',
          vModel: 'mh_address',
          config: {
            md: 6
          },
          input: {
            type: 'form-input'
          }
        },
        {
          label: 'Rencana Berkegiatan *',
          vModel: 'mh_rencana_keg',
          config: {
            md: 12
          },
          input: {
            type: 'time',
            format: 'D MMMM - hh:mm A'
          }
        }
      ],
      /* End Of Modal Create Rencana Kegiatan Manual Handling */

      yoRender: 1,
      /* PROGRESS | RENDERING | REFS */
      yoTimeoutDebounce: null,
      yoUserData: null,
      yo_isLoading: false,
      yo_isLoadingValue: 25,
      yoTimer: null /* fetching data progress bar #interval */,
      dir: false,
      /* PROGRESS | RENDERING | REFS */

      /* GOOD TABLE DATA() DONT MODIFIED */
      onFiltering: false,
      total_records: 0,
      rows: [],
      selectedRows: [],
      serverParams: {
        columnFilters: {},
        searchTerm: '',
        sort: {
          field: 'created_at',
          type: 'DESC'
        },
        page: 1,
        perPage: 10
      },
      /* GOOD TABLE DATA() */

      /* GOOD TABLE DATA() ABLE TO MODIFIED */
      apiSAL: 'fhm_keg_manh',
      realisasiColumns: [
        {
          label: 'Rencana Kegiatan',
          field: 'rencana_desc',
          width: '200px'
        },
        {
          label: 'Realisasi Kegiatan',
          field: 'realisasi_timeline',
          width: '500px'
        }
      ],
      columns: [
        // {
        //   label: 'No PBMBB',
        //   field: 'mh_no_pbmbb'
        //   // filterOptions: {
        //   //   enabled: true,
        //   //   placeholder: 'Cari No PBMBB'
        //   // }
        // },
        {
          label: 'Name Barang',
          field: 'mh_name_barang'
          // filterOptions: {
          //   enabled: true,
          //   placeholder: 'Cari Barang'
          // }
        },
        {
          label: 'Segment',
          field: 'mh_pemilik_brg'
          // filterOptions: {
          //   enabled: true,
          //   placeholder: 'Cari Segment'
          // }
        },
        {
          label: 'Rencana Berkegiatan',
          field: 'mh_rencana_keg',
          // filterOptions: {
          //   enabled: true,
          //   placeholder: 'Cari Rencana Berkegiatan'
          // },
          formatFn: this.dateHumanReadAble
        },
        // {
        //   label: 'Waktu Berkegiatan',
        //   field: 'mh_rencana_keg_start',
        //   // filterOptions: {
        //   //   enabled: true,
        //   //   placeholder: 'Cari Waktu Berkegiatan'
        //   // },
        //   formatFn: this.dateHumanReadAble
        // },
        // {
        //   label: 'Waktu Selesai',
        //   field: 'mh_rencana_keg_end',
        //   // filterOptions: {
        //   //   enabled: true,
        //   //   placeholder: 'Cari Waktu Selesai'
        //   // },
        //   formatFn: this.dateHumanReadAble
        // },
        {
          label: 'Ubah / Realisasikan',
          field: 'action'
        }
      ],
      /* GOOD TABLE DATA() ABLE TO MODIFIED */

      /* FIELD MANIPULATION */
      lte: moment().format('yyyy-MM-DDT01:00'),
      gte: moment().format('yyyy-MM-DDT23:59'),
      dpconfig: {
        wrap: true,
        enableTime: true,
        time_24hr: true,
        altInput: true,
        altFormat: 'j F Y - H:i',
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: 'today'
      }
    }
  },
  computed: {
    permissionKegiatan() {
      return (props) => {
        console.log('this.userID::', this.userID)
        console.log('props::', props)
        if (this.$can('SAL', '')) {
          return true
        }
        if (props === this.userID) {
          return true
        } else {
          return false
        }
      }
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    disableCrudX() {
      if (
        this.disableCrudMutation === 'KAPAL BERANGKAT' ||
        this.disableCrudMutation === 'KEGIATAN DIBATALKAN'
      ) {
        // if (this.$can('manage', 'all')) {
        //   return false
        // }
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    isVerticalMenuCollapsed() {
      console.log('collapse', this.isVerticalMenuCollapsed)
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        // this.yoRender += 1
        // this.serverParams = {
        //   ...this.serverParams,
        // }
      }, 300)
    },
    anIncomingNotification() {
      console.log(
        'RMSungai.vue : an incoming mesage key : ',
        this.anIncomingNotification
      )
    },
    'document.body.style.zoom': function () {
      console.log('screen change')
      // this.yoRender += 1
    }
  },
  mounted() {
    document.body.style.zoom = '100%'
  },
  destroyed() {
    document.body.style.zoom = '100%'
  },
  created() {
    const yoUserData = getUserData()
    this.yoUserData = yoUserData
    this.fetchData(true, '')
  },
  beforeDestroy() {
    this.isTimerDone()
  },
  methods: {
    async exportDataTable(row) {
      let excel = []
      /* easyway merge on be | excel */
      if (row.length !== 0) {
        row.forEach((it) => {
          if (it.rel_keg_mhs.length > 1) {
            /* do duplicate */
            it.rel_keg_mhs.forEach((its) => {
              excel.push({
                ...it /* rencana */,
                rel_keg_mhs: its /* realisasi */
              })
            })
          } else {
            /* dont duplicate */
            excel.push({
              ...it,
              rel_keg_mhs: it.rel_keg_mhs[0]
            })
          }
        })
      } else {
        this.rows[0].children.forEach((it) => {
          if (it.rel_keg_mhs.length > 1) {
            /* do duplicate */
            it.rel_keg_mhs.forEach((its) => {
              excel.push({
                ...it /* rencana */,
                rel_keg_mhs: its /* realisasi */
              })
            })
          } else {
            /* dont duplicate */
            excel.push({
              ...it,
              rel_keg_mhs: it.rel_keg_mhs[0]
            })
          }
        })
      }
      await useJwt.http
        .post(`fhm_keg_manh/export/excel`, {
          data_hight_light: this.dataHighLight,
          raw_to_excel: excel
        })
        .then((response) => {
          const uri = `${process.env.VUE_APP_BACKEND_URL}/dir_kegiatan_pipelines/${response.data.link_file_excel}`
          console.log(uri)
          window.open(uri)
        })
        .catch((error) => {
          console.log('error ', error)
        })
    },
    durationAsString(start, end) {
      const duration = moment.duration(moment(end).diff(moment(start)))

      //Get Days
      const days = Math.floor(duration.asDays()) // .asDays returns float but we are interested in full days only
      const daysFormatted = days ? `${days} days ` : '' // if no full days then do not display it at all

      //Get Hours
      const hours = duration.hours()
      const hoursFormatted = `${hours} hr `

      //Get Minutes
      const minutes = duration.minutes()
      const minutesFormatted = `${minutes} min`

      //Get seconds
      var seconds = duration.seconds()
      const secondsFormatted = ` ${seconds} sec`

      //Get milliseconds
      var milliseconds = moment(end).diff(moment(start))
      const millisecondsFormatted = ` ( ${milliseconds} ms )`

      return [
        daysFormatted,
        hoursFormatted,
        minutesFormatted,
        millisecondsFormatted
      ].join('')
    },
    checkRealisasi(rel_keg_mh) {
      let total = 0
      rel_keg_mh.forEach((el) => {
        console.log('object:::', el.mh_volume_rl)
        total += Number(el.mh_volume_rl)
      })
      console.log('total::', total)
      return total
    },
    methodModalRNFhmMHResolveFetchDetail(response) {
      return response.data.fhm_keg_manh
    },
    methodModalRNFhmMHResolveFetchDetailSecond(response) {
      return response.data.rel_keg_mhs
    },
    methodModalRNFhmMH(
      val = false,
      callBack = null,
      operation = null,
      fhm_keg_manh_id = null
    ) {
      if (this.disableCrudX) {
        this.$alert({
          title: `Action was disabled`,
          text: `tidak bisa melakukan aksi, karena form status 'KAPAL BERANGKAT'`,
          variant: 'warning',
          icon: 'XCircleIcon'
        })
        return
      }
      if (operation) {
        this.operationModalRNFhmMH = operation
        this.dataIDModalRNFhmMH = `${this.dataPayloadModalRNFhmMH.form_hulu_migas_id}/${fhm_keg_manh_id}`
      }
      this.dataModalRNFhmMH = val

      if (callBack) {
        console.log('callBack: ', callBack)
        if (callBack.includes('berhasil')) {
          this.$alert({
            title: `Rencana Kegiatan`,
            text: `${callBack}`,
            variant: 'success',
            icon: 'CheckCircleIcon'
          })
          this.fetchData(true, '')
        } else {
          this.$alert({
            title: `Rencana Kegiatan`,
            text: `Tidak berhasil membuat rencana kegiatan`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      }
    },
    methodModalHGRNFhmMH(
      val = false,
      callBack = null,
      operation = null,
      fhm_keg_manh_id = null
    ) {
      if (this.disableCrudX) {
        this.$alert({
          title: `Action was disabled`,
          text: `tidak bisa melakukan aksi, karena form status 'KAPAL BERANGKAT'`,
          variant: 'warning',
          icon: 'XCircleIcon'
        })
        return
      }
      if (operation) {
        this.operationModalRNFhmMH = operation
        this.dataIDModalRNFhmMH = `${this.dataPayloadModalRNFhmMH.form_hulu_migas_id}/${fhm_keg_manh_id}`
        this.dataIDModalRNFhmMHOnly = `${fhm_keg_manh_id}`
      }
      this.dataModalHGRNFhmMH = val

      if (callBack) {
        console.log('callBack: ', callBack)
        if (callBack.includes('berhasil')) {
          this.$alert({
            title: `Rencana Kegiatan`,
            text: `${callBack}`,
            variant: 'success',
            icon: 'CheckCircleIcon'
          })
          this.fetchData(true, '')
        } else {
          this.$alert({
            title: `Rencana Kegiatan`,
            text: `Tidak berhasil membuat rencana kegiatan`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      }
    },
    /* START FROM HERE DONT MODIFIED */
    moment,
    isTimerDone() {
      clearInterval(this.yoTimer)
      this.yoTimer = null
      this.yo_isLoading = false
    },
    isTimerStart() {
      this.yo_isLoading = true
      this.rows = []
      this.yoTimer = setInterval(() => {
        // eslint-disable-next-line
        if (this.yo_isLoadingValue <= 75 && this.yo_isLoading >= 0) {
          this.yo_isLoadingValue += 25
        } else {
          this.yo_isLoadingValue = 0
        }
      }, 1000)
    },
    dateHumanReadAble(value) {
      const format = moment(value).format('D MMMM YYYY - HH:mm')
      if (format === 'Invalid date') {
        return 'Belum Diisi ❗️'
      }
      return format
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    selectionChanged(params) {
      console.log('selectionChanged: ', params)
      this.selectedRows = params.selectedRows
    },
    onPageChange(params) {
      console.log('onPageChange: ', params)
      this.updateParams({ page: params.currentPage })
      this.fetchData(true, '')
    },

    onPerPageChange(params) {
      console.log('onPerPageChange: ', params)
      this.updateParams({ perPage: params.currentPerPage })
      this.fetchData(true, '')
    },

    onSortChange(params) {
      console.log(params)
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      })
      this.fetchData(true, '')
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.fetchData(true, '')
    },
    onSearchTable(params) {
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        this.updateParams({ searchTerm: params.searchTerm })
        this.updateParams({ page: 1 })
        this.fetchData(true, '')
      }, 300)
    },
    /* END FROM HERE DONT MODIFIED */

    /* FETCH DATA | AXIOS */
    fetchData({ state = null, keyword = null }) {
      this.dataPayloadModalRNFhmMH.form_hulu_migas_id = this.fhmID
      this.isTimerStart()
      // const { page } = this.serverParams
      this.serverParams = {
        ...this.serverParams,
        // page,
        // stateFilter: this.stateFilter, // kapal pernah|sedang sandar
        // timeGT: this.gte,
        // timeLTE: this.lte,
        keyword
      }
      useJwt.http
        .get(`${this.apiSAL}/${this.fhmID}`, {
          params: { ...this.serverParams }
        })
        .then((res) => {
          const { fhm_keg_manhs, total_records } = res.data
          // eslint-disable-next-line camelcase
          this.total_records = total_records
          // eslint-disable-next-line camelcase
          this.rows = fhm_keg_manhs
          this.isTimerDone()
          // this.yoRender += 1
        })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
$color-yo-border: 5px solid #2f2f2f1b;
#app {
  .app-content {
    .content-wrapper {
      .content-body {
        .monitoring {
          .yo-good-table::v-deep .vgt-global-search {
            border-top: 5px solid #2f2f2f1b;
            border-left: 5px solid #2f2f2f1b;
            border-right: 5px solid #2f2f2f1b;
            .vgt-global-search__input {
              border: none !important;
              .input__icon {
                font-weight: 700 !important;
              }
              .vgt-input {
                font-weight: 700 !important;
                color: black;
                border: double;
              }
            }
          }
          .yo-good-table::v-deep .vgt-table {
            border: $color-yo-border !important;
            width: 100% !important;
            thead {
              th {
                border: $color-yo-border !important;
              } /* header custom */
              .filter-th {
                input {
                  border: $color-yo-border !important;
                  font-weight: bolder;
                }
              }
            }
            tbody {
              th {
                border: $color-yo-border !important;
              } /* left checkbox */
              td {
                border: $color-yo-border !important;
              } /* right checkbox */
            }
          }
          .yo-row {
            .yo-dropdown >>> .dropdown-menu {
              z-index: 999;
            }
            .yo-dropdown::v-deep .dropdown-menu {
              z-index: 999;
            }
          }
        }
      }
    }
  }
}
</style>
