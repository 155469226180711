var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "monitoring"
  }, [_c('FormModal', {
    key: "ModalRNFhmMH".concat(_vm.keyModalRNFhmMH),
    attrs: {
      "uri": "/fhm_keg_manh",
      "title": "Form Rencana Kegiatan (Manual Handling)",
      "is-modal-active": _vm.dataModalRNFhmMH,
      "payload": _vm.dataPayloadModalRNFhmMH,
      "column": _vm.columnModalRNFhmMH,
      "action": _vm.operationModalRNFhmMH,
      "data-i-d": "".concat(_vm.dataIDModalRNFhmMH),
      "resolve-fetch-detail": _vm.methodModalRNFhmMHResolveFetchDetail
    },
    on: {
      "update:isModalActive": function updateIsModalActive($event) {
        _vm.dataModalRNFhmMH = $event;
      },
      "update:is-modal-active": function updateIsModalActive($event) {
        _vm.dataModalRNFhmMH = $event;
      },
      "closeModal": _vm.methodModalRNFhmMH
    }
  }), _c('FormModalMH', {
    key: "ModalHGRNFhmMH".concat(_vm.keyModalHGRNFhmMH),
    attrs: {
      "uri": "/fhm_keg_manh",
      "uriSecond": "/rel_keg_mh",
      "title": "Form Realisasi Kegiatan (Manual Handling)",
      "is-modal-active": _vm.dataModalHGRNFhmMH,
      "payload": _vm.dataPayloadModalRNFhmMH,
      "column": _vm.columnModalHGRNFhmMH,
      "columnRepeat": _vm.columnModalHGRepeateRNFhmMH,
      "high-light": _vm.highLightModalHGRNFhmMH,
      "action": _vm.operationModalRNFhmMH,
      "data-i-d": "".concat(_vm.dataIDModalRNFhmMH),
      "second-i-d": "".concat(_vm.dataIDModalRNFhmMHOnly),
      "form-chain-hm-i-d": _vm.formChainHmID,
      "resolve-fetch-detail": _vm.methodModalRNFhmMHResolveFetchDetail,
      "resolve-fetch-detail-second": _vm.methodModalRNFhmMHResolveFetchDetailSecond,
      "button-ok-title": " Simpan Realisasi"
    },
    on: {
      "update:isModalActive": function updateIsModalActive($event) {
        _vm.dataModalHGRNFhmMH = $event;
      },
      "update:is-modal-active": function updateIsModalActive($event) {
        _vm.dataModalHGRNFhmMH = $event;
      },
      "closeModal": _vm.methodModalHGRNFhmMH
    }
  }), !_vm.isRealisasi ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('div', {
    staticClass: "d-flex align-items-center"
  })])], 1), _c('b-col', {
    staticClass: "text-right mb-2",
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "custom-search d-flex justify-content-end"
  }, [_vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' ? _c('b-button', {
    staticClass: "button-tambah mr-1",
    attrs: {
      "id": "tambah-data",
      "variant": 'success',
      "disabled": _vm.disableCrudX
    },
    on: {
      "click": function click($event) {
        return _vm.methodModalRNFhmMH(true, null, 'is_add');
      }
    }
  }, [_c('span', [_c('strong', [_c('feather-icon', {
    attrs: {
      "icon": "FilePlusIcon"
    }
  }), _vm._v(" Tambah Data ")], 1)])]) : _vm._e(), _c('b-button', {
    staticClass: "button-export mr-1",
    attrs: {
      "id": "export-data",
      "variant": 'warning'
    },
    on: {
      "click": function click($event) {
        return _vm.exportDataTable(_vm.selectedRows);
      }
    }
  }, [_c('span', [_c('strong', [_c('feather-icon', {
    attrs: {
      "icon": "FilePlusIcon"
    }
  }), _vm._v(" Export to Excel ( " + _vm._s(_vm.selectedRows.length === 0 ? 'ALL Rows' : "Only Row Selected") + " "), _vm.selectedRows.length !== 0 ? _c('b-badge', [_vm._v(" " + _vm._s(_vm.selectedRows.length) + " ")]) : _vm._e(), _vm._v(" ) ")], 1)])])], 1)])], 1) : _vm._e(), _vm.yo_isLoading ? _c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Memuat data… " + _vm._s(_vm.yo_isLoadingValue + '%') + " ")]), _c('b-progress', {
    key: _vm.yo_isLoadingValue,
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": _vm.yo_isLoadingValue,
      "variant": "info",
      "max": "100"
    }
  })], 1) : _vm._e(), _c('vue-good-table', {
    key: _vm.yoRender,
    staticClass: "yo-good-table",
    attrs: {
      "mode": "remote",
      "total-rows": _vm.total_records,
      "columns": _vm.isRealisasi ? _vm.realisasiColumns : _vm.columns,
      "rows": _vm.rows,
      "is-loading": _vm.yo_isLoading,
      "rtl": _vm.direction,
      "search-options": {
        enabled: true,
        skipDiacritics: true,
        placeholder: 'Search this table'
      },
      "select-options": {
        enabled: !_vm.isRealisasi,
        selectOnCheckboxOnly: true,
        // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'Rows Selected',
        clearSelectionText: 'Clear',
        disableSelectInfo: false,
        // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.serverParams.perPage,
        mode: 'pages',
        position: 'bottom',
        // perPageDropdown: [3, 7, 9],
        // dropdownAllowAll: false,
        // setCurrentPage: 1,
        jumpFirstOrLast: true,
        // firstLabel : 'First Page'
        // lastLabel : 'Last Page'
        // nextLabel: 'next',
        // prevLabel: 'prev',
        // rowsPerPageLabel: 'Rows per page',
        // ofLabel: 'of',
        // pageLabel: 'page', // for 'pages' mode
        // allLabel: 'All',
        infoFn: function infoFn(params) {
          return "my own page ".concat(params.firstRecordOnPage);
        }
      },
      "group-options": {
        enabled: true
      },
      "compact-mode": "",
      "theme": "polar-bear",
      "style-class": "vgt-table bordered",
      "line-numbers": true,
      "fixed-header": false,
      "max-height": "700px"
    },
    on: {
      "update:isLoading": function updateIsLoading($event) {
        _vm.yo_isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.yo_isLoading = $event;
      },
      "on-search": _vm.onSearchTable,
      "on-selected-rows-change": _vm.selectionChanged,
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-header-row",
      fn: function fn(props) {
        return [_vm._v(" Kegiatan "), _c('BBadge', {
          staticClass: "mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('strong', [_vm._v(_vm._s(props.row.label))])]), _c('BBadge', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v(" Jumlah Data : " + _vm._s(props.row.children.length) + " ")])];
      }
    }, {
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field === 'mh_no_pbmbb' ? _c('span', [_c('strong', [_vm._v(" " + _vm._s(props.row.mh_no_pbmbb) + " "), _c('br'), _c('span', {
          domProps: {
            "textContent": _vm._s("".concat(props.row.kegiatan_hm.keg_name === 'BONGKAR' ? 'Asal: ' : 'Tujuan: ').concat(props.row.mh_address))
          }
        })])]) : props.column.field === 'mh_rencana_keg' ? _c('span', [_c('strong', [_c('div', {
          staticClass: "divider my-2"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_c('div', [_vm._v(" " + _vm._s(_vm.dateHumanReadAble(props.row.mh_rencana_keg)) + " ")]), _c('small', [_vm._v("Signatured: " + _vm._s(props.row.user.full_name))])])])])]) : props.column.field === 'rencana_desc' ? _c('span', [_c('div', {
          staticClass: "divider my-2"
        }, [_c('div', {
          staticClass: "divider-text"
        }, [_c('small', [_vm._v("Segment " + _vm._s(props.row.mh_pemilik_brg))]), _c('h3', [_vm._v(" " + _vm._s(props.row.mh_name_barang) + " " + _vm._s(props.row.mh_volume) + " " + _vm._s(props.row.mh_satuan) + " ")]), _c('div', [_vm._v(" Direncanakan "), _c('br'), _vm._v(" " + _vm._s(_vm.dateHumanReadAble(props.row.mh_rencana_keg)) + " ")]), _c('hr'), _c('small', [_vm._v("Signatured "), _c('br'), _vm._v(_vm._s(props.row.user.full_name))])])])]) : props.column.field === 'realisasi_timeline' ? _c('span', [!!props.row.rel_keg_mhs.length ? _c('app-timeline', _vm._l(props.row.rel_keg_mhs, function (item, index) {
          return _c('app-timeline-item', {
            key: index,
            attrs: {
              "title": "Sebanyak ".concat(item.mh_volume_rl, " ").concat(item.mh_satuan_rl, " ( Menggunakan ").concat(item.mh_equiptment_rl, " )"),
              "subtitle": "JML Tenaga Kerja ".concat(item.mh_jml_tng_krj_rl, " <br/>"),
              "icon": "ClockIcon",
              "time": "Selama 1 day 1 hours 1 minutes",
              "variant": "success"
            }
          }, [_c('div', {
            staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
          }, [_c('h6', [_vm._v(" " + _vm._s("Sebanyak ".concat(item.mh_volume_rl, " ").concat(item.mh_satuan_rl)) + " ")]), _c('small', {
            staticClass: "text-muted"
          }, [_vm._v("03:00 PM")])]), _c('p', [_vm._v(" Kegiatan dimulai pada tanggal "), _c('strong', [_vm._v(" " + _vm._s("".concat(_vm.dateHumanReadAble(item.mh_time_start_rl))) + " ")]), _c('br'), _vm._v(" sampai "), _c('strong', [_vm._v(" " + _vm._s("".concat(_vm.dateHumanReadAble(item.mh_time_end_rl))) + " ")])]), _c('div', {
            staticClass: "d-flex flex-sm-row flex-column justify-content-between align-items-start"
          }, [_c('div', {
            staticClass: "mb-1 mb-sm-0"
          }, [_c('small', [_c('span', {
            staticClass: "text-muted mb-50 d-block"
          }, [_vm._v("JML Tenaga Kerja")])]), _c('div', [_c('b-avatar', {
            staticClass: "mr-50",
            attrs: {
              "text": item.mh_jml_tng_krj_rl,
              "size": "24",
              "variant": "light-primary"
            }
          })], 1)]), _c('div', {
            staticClass: "mb-1 mb-sm-0"
          }, [_c('small', [_c('span', {
            staticClass: "text-muted mb-50 d-block"
          }, [_vm._v("Equiptment")])]), _c('small', [_c('span', [_vm._v(_vm._s("".concat(item.mh_equiptment_rl)))])])]), _c('div', [_c('small', [_c('span', {
            staticClass: "text-muted mb-50 d-block"
          }, [_vm._v("Total Waktu")])]), _c('small', [_c('span', [_vm._v(" " + _vm._s("".concat(_vm.durationAsString(item.mh_time_start_rl, item.mh_time_end_rl))) + " ")])])])])]);
        }), 1) : _c('app-timeline', [_c('app-timeline-item', {
          attrs: {
            "title": "Belum melakukan realisasi kegiatan",
            "subtitle": "You are just one step away from your goal",
            "icon": "InfoIcon",
            "time": "a minutes ago",
            "variant": "danger"
          }
        })], 1)], 1) : props.column.field === 'mh_name_barang' ? _c('span', [_c('strong', [_c('span', [_vm._v(_vm._s(props.row.mh_name_barang))]), _c('br'), _c('span', [_vm._v("Jumlah Unit: " + _vm._s(props.row.mh_volume) + " " + _vm._s(props.row.mh_satuan))]), _c('br'), _c('span', {
          domProps: {
            "textContent": _vm._s("".concat(props.row.kegiatan_hm.keg_name === 'BONGKAR' ? 'Asal: ' : 'Tujuan: ').concat(props.row.mh_address))
          }
        })])]) : props.column.field === 'action' ? _c('span', [_vm.$store.state.app.roleName !== 'viewer' ? _c('span', [_vm.permissionKegiatan(props.row.user_id) ? _c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "30"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          attrs: {
            "variant": "primary",
            "toggle-class": "p-0",
            "no-caret": ""
          },
          on: {
            "click": function click($event) {
              return _vm.methodModalRNFhmMH(true, null, 'is_edit', props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50 cursor-pointer",
          attrs: {
            "icon": "PenToolIcon",
            "size": "20"
          }
        }), _c('span', [_vm._v("Ubah Rencana Kegiatan")])], 1), _c('b-dropdown-item', {
          attrs: {
            "variant": "success",
            "toggle-class": "p-0",
            "no-caret": ""
          },
          on: {
            "click": function click($event) {
              return _vm.methodModalHGRNFhmMH(true, null, 'is_edit', props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50 cursor-pointer",
          attrs: {
            "icon": "AnchorIcon",
            "size": "20"
          }
        }), _c('span', [_vm._v("Realisasikan Kegiatan")])], 1)], 1)], 1) : _c('span', [_c('b-alert', {
          staticClass: "mb-0",
          attrs: {
            "variant": "primary",
            "show": ""
          }
        }, [_c('div', {
          staticClass: "alert-body"
        }, [_vm._v("No Access")])])], 1)]) : _vm._e(), _c('br'), props.row.rel_keg_mhs.length ? _c('span', [_vm._v(" Sudah direalisasikan " + _vm._s(_vm.checkRealisasi(props.row.rel_keg_mhs)) + " " + _vm._s(props.row.mh_satuan) + " ")]) : _c('span', [_c('span', [_c('b-alert', {
          staticClass: "mb-0",
          attrs: {
            "variant": "warning",
            "show": ""
          }
        }, [_c('div', {
          staticClass: "alert-body"
        }, [_vm._v("Belum direalisasikan")])])], 1)])]) : _c('span', [_c('strong', [_vm._v(_vm._s(props.formattedRow[props.column.field]))])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }