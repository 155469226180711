var render = function () {
  var _vm$form$form_chain_h, _vm$form$form_hulu_mi, _vm$form$form_tug_boa, _vm$form;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "invoice-preview-wrapper"
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.$router.currentRoute.params.id === undefined
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Error fetching summary data")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" No summary found with this summary id. Check "), _c('b-link', {
    staticClass: "alert-link",
    attrs: {
      "to": {
        name: 'apps-invoice-list'
      }
    }
  }, [_vm._v(" Summary List ")]), _vm._v(" for other summaries. ")], 1)]), _vm.invoiceData ? _c('b-row', {
    staticClass: "invoice-preview"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "xl": "10",
      "md": "9"
    }
  }, [_c('b-card', {
    staticClass: "invoice-preview-card",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', [_c('div', {
    staticClass: "logo-wrapper"
  }, [_c('h3', {
    staticClass: "text-primary invoice-logo"
  }, [_vm._v(" Hulu Migas - Pelayanan Kapal ")])]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v("PT. Sarana Abadi Lestari")]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v("Kec. Palaran, Kota Samarinda")]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v(" +1 (123) 456 7891, +44 (876) 543 2198 ")]), _c('p', {
    staticClass: "card-text mb-0"
  }, [_vm._v("Kalimantan Timur 75261")])]), _c('div', {
    staticClass: "mt-md-0 mt-2"
  }, [_c('h4', {
    staticClass: "invoice-title"
  }, [_vm._v(" Summary "), _c('span', {
    staticClass: "invoice-number"
  }, [_vm._v("#" + _vm._s((_vm$form$form_chain_h = _vm.form.form_chain_hm_id) !== null && _vm$form$form_chain_h !== void 0 ? _vm$form$form_chain_h : '-') + "#" + _vm._s((_vm$form$form_hulu_mi = _vm.form.form_hulu_migas_id) !== null && _vm$form$form_hulu_mi !== void 0 ? _vm$form$form_hulu_mi : '-') + "#" + _vm._s((_vm$form$form_tug_boa = _vm.form.form_tug_boat_id) !== null && _vm$form$form_tug_boa !== void 0 ? _vm$form$form_tug_boa : '-'))])]), _c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "CalendarIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Date of Application')) + " ")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.created_at)) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Date of Departure')) + " ")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.sandar_end_time) || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Tanggal Sandar ")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.sandar_start_time) || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "BookOpenIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Activity')) + " ")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.kegiatan) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "CpuIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Jenis Kapal')) + " ")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.jenis_kapal) + " ")])]), _c('tr', [_c('th', [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "FlagIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Form Status')) + " ")])], 1), _c('td', [_c('b-badge', {
    attrs: {
      "variant": _vm.formStatusVariant(_vm.form.schedule.form_status)
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.form_status) + " ")]), _c('br'), _vm.form.schedule.jenis_kapal === 'Barge' && _vm.form.schedule.no_rpkro && _vm.form.schedule.no_rpkro_tb && _vm.form.schedule.form_status !== 'KAPAL BERANGKAT' ? _c('span', {
    domProps: {
      "textContent": _vm._s(" DIIJINKAN SANDAR")
    }
  }) : _vm.form.schedule.jenis_kapal !== 'Barge' && _vm.form.schedule.no_rpkro && _vm.form.schedule.form_status !== 'KAPAL BERANGKAT' ? _c('span', {
    domProps: {
      "textContent": _vm._s(" DIIJINKAN SANDAR")
    }
  }) : _vm.form.schedule.form_status === 'KAPAL BERANGKAT' || _vm.form.schedule.form_status === 'KAPAL SANDAR' || _vm.form.schedule.form_status === 'KEGIATAN DIBATALKAN' ? _c('span', {
    domProps: {
      "textContent": _vm._s(" ")
    }
  }) : _vm._e()], 1)])])])])]), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('hr', {
    staticClass: "invoice-spacing"
  }) : _vm._e(), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', {
    staticClass: "col col-md-6 p-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _vm._v(" Main Boat "), _c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  })], 1)], 1)]), _c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nama Kapal')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat_hm.boat_name) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor PKK')) + " ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50 cursor-pointer",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_pkk);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_pkk) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('GT')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat_hm.gt) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('LOA')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(_vm._s(_vm.form.dm_river_boat_hm.loa) + " M")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Captain')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat_hm.captain_name) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Captain Telp')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat_hm.captain_telp) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Shipping Agent')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat_hm.dm_shipping_agent.shipping_name) + " ")])])])]), _c('div', {
    staticClass: "col col-md-6 p-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _vm._v(" Tug Boat "), _c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  })], 1)], 1)]), _c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nama Kapal')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.name_tug_boat) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor PKK')) + " ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50 cursor-pointer",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_pkk_tug_boat);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_pkk_tug_boat) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('GT')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.gt_tug_boat) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('LOA')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(_vm._s(_vm.form.tug_boat.loa_tug_boat) + " M")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Captain')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.captain_name_tug_boat) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Captain Telp')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.captain_telp_tug_boat) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Shipping Agent')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.dm_shipping_agent.shipping_name) + " ")])])])])])]) : _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _vm._v(" KAPAL "), _c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', {
    staticClass: "col col-md-6 p-0"
  }, [_c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nama Kapal')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat_hm.boat_name) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor PKK')) + " ")])]), _c('td', {
    staticClass: "pb-50",
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_pkk);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_pkk) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('GT')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat_hm.gt) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('LOA')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(_vm._s(_vm.form.dm_river_boat_hm.loa) + " M")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Captain')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat_hm.captain_name) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Captain Telp')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat_hm.captain_telp) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Shipping Agent')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat_hm.dm_shipping_agent.shipping_name) + " ")])])])])])]), _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon"
    }
  }), _vm._v(" Schedule "), _c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', {
    staticClass: "col col-md-6 p-0"
  }, [_c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Asal Kapal')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.dm_asal_kapal.asal) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Rencana Posisi Jetty')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.rencana_position_jetty) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Rencana Posisi Baris Jetty')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.rencana_baris_position_jetty) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Rencana Kapal Sandar')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.schedule.rencana_sandar)) + " ")])])])])])]), _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "HardDriveIcon"
    }
  }), _vm._v(" Upload File "), _c('feather-icon', {
    attrs: {
      "icon": "HardDriveIcon"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, _vm._l(_vm.isHaveFile, function (item, index) {
    return _c('app-timeline-item', {
      key: index
    }, [_c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
    }), _c('p', [_c('b-img', {
      staticClass: "mr-1",
      attrs: {
        "src": _vm.icons(item),
        "height": "auto",
        "width": "20"
      }
    }), _c('span', {
      staticClass: "align-bottom"
    }, [_c('a', {
      staticStyle: {
        "color": "grey"
      },
      attrs: {
        "href": item.download_link,
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(_vm._s(item.original_name))])])], 1), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v("Size " + _vm._s(item.size) + " "), _c('strong', [_vm._v("[ click name file, to download.]")])])]);
  }), 1)]), _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CheckSquareIcon"
    }
  }), _vm._v(" Kegiatan "), _c('feather-icon', {
    attrs: {
      "icon": "CheckSquareIcon"
    }
  })], 1)], 1)]), _c('b-tabs', {
    staticClass: "tabs-custom",
    attrs: {
      "content-class": "",
      "align": "center"
    }
  }, [_vm.form.form_hulu_migas_id ? _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "GitMergeIcon",
            "size": "30"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "2em"
          }
        }, [_vm._v("PIPELINE")])])];
      },
      proxy: true
    }], null, false, 1792060546)
  }, [_c('TablePipeLineGood', {
    key: _vm.form.table_pipe_line,
    ref: "TablePipeLineOutside",
    attrs: {
      "fhm-i-d": _vm.form.form_hulu_migas_id,
      "is-realisasi": true
    }
  })], 1) : _vm._e(), _vm.form.form_hulu_migas_id ? _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "GitMergeIcon",
            "size": "30"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "2em"
          }
        }, [_vm._v("SEMI MEKANIS")])])];
      },
      proxy: true
    }], null, false, 4087894148)
  }, [_c('TableManualHandlingGood', {
    key: _vm.form.table_mh,
    ref: "TableManualHandlingOutside",
    attrs: {
      "fhm-i-d": _vm.form.form_hulu_migas_id,
      "is-realisasi": true
    }
  })], 1) : _vm._e()], 1), _c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_vm.loadingTotalKegiatan ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _c('span', [_vm._v(_vm._s(this.total_realisasi_kegiatan))]), _c('br'), _c('span', [_vm._v("Total Semua Waktu Berkegiatan")])], 1)])], 1), _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('br'), _c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MapIcon"
    }
  }), _vm._v(" Jetty Man "), _c('feather-icon', {
    attrs: {
      "icon": "MapIcon"
    }
  })], 1), _c('br'), _vm._v(" [ Tujuan Kapal : " + _vm._s((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : _vm$form.tujuan_kapal) + " ] ")], 1)]), _c('b-card-body', [_c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h5', [_vm._v(_vm._s(_vm.form.dm_river_boat_hm.boat_name))]), _c('span', {
    staticStyle: {
      "@media (min-width": "390px) and (max-width: 768px) {",
      "word-wrap": "break-word",
      "width": "50px",
      "white-space": "normal"
    }
  }, [_vm._v(" " + _vm._s(this.form_realisasi.rka1) + " ")]), _c('br'), _c('strong', [_c('span', [_vm._v("Sandar → Berangkat")])])])])]), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('div', [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h5', [_vm._v(_vm._s(_vm.form.tug_boat.name_tug_boat))]), _c('span', {
    staticStyle: {
      "@media (min-width": "390px) and (max-width: 768px) {",
      "word-wrap": "break-word",
      "width": "50px",
      "white-space": "normal"
    }
  }, [_vm._v(_vm._s(this.form_realisasi.rka2))]), _c('br'), _c('strong', [_c('span', [_vm._v("Sandar → Berangkat")])])])])]) : _vm._e()])]), _c('b-tabs', {
    staticClass: "tabs-custom",
    attrs: {
      "content-class": "",
      "align": "center"
    }
  }, [_c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "GitMergeIcon",
            "size": "25"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat_hm.boat_name) + " ")])])];
      },
      proxy: true
    }], null, false, 2398175350)
  }, [_c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('NestedIO', {
    key: _vm.form.table_jetty_position,
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 p-0",
    attrs: {
      "uri": "realisasi_posisi_kapal",
      "model-id": _vm.form.form_hulu_migas_id,
      "model": "form_hulu_migas",
      "posisi": _vm.jetty_positions,
      "posisi-baris": _vm.jetty_baris_positions,
      "mode-summary": true,
      "payload": {
        model_id: null,
        model: null,
        is_first: null,
        posisi_awal: null,
        posisi_awal_baris: null,
        posisi_skrg: null,
        posisi_skrg_baris: null,
        reason: null,
        waktu_pindah: null
      },
      "column-repeat": [{
        fe_id: 1
      }],
      "d-t-o": [],
      "resolve-fetch": function resolveFetch(response) {
        return response.data.realisasi_posisi_kapals;
      },
      "buttonOkTitle": ""
    }
  })], 1)]), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "GitMergeIcon",
            "size": "25"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.name_tug_boat) + " ")])])];
      },
      proxy: true
    }], null, false, 2851881023)
  }, [_c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('NestedIO', {
    key: _vm.form.table_jetty_position,
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 p-0",
    attrs: {
      "uri": "realisasi_posisi_kapal",
      "model-id": _vm.form.form_tug_boat_id,
      "model": "form_tug_boat",
      "posisi": _vm.jetty_positions,
      "posisi-baris": _vm.jetty_baris_positions,
      "mode-summary": true,
      "payload": {
        model_id: null,
        model: null,
        is_first: null,
        posisi_awal: null,
        posisi_awal_baris: null,
        posisi_skrg: null,
        posisi_skrg_baris: null,
        reason: null,
        waktu_pindah: null
      },
      "column-repeat": [{
        fe_id: 1
      }],
      "d-t-o": [],
      "resolve-fetch": function resolveFetch(response) {
        return response.data.realisasi_posisi_kapals;
      },
      "buttonOkTitle": ""
    }
  })], 1)]) : _vm._e(), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "GitMergeIcon",
            "size": "25"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          }
        }, [_vm._v("Boat Timeline ")])])];
      },
      proxy: true
    }], null, false, 1129164927)
  }, [_c('ChainTimeline', {
    attrs: {
      "barge-or-tug": "barge",
      "data-i-d": _vm.form.form_chain_hm_id
    }
  })], 1) : _vm._e()], 1)], 1), _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('br'), _c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UnlockIcon"
    }
  }), _vm._v(" Admin SAL "), _c('feather-icon', {
    attrs: {
      "icon": "UnlockIcon"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', [_vm.form.schedule.jenis_kapal === 'Barge' ? _c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat_hm.boat_name) + " ")])], 1)]) : _vm._e(), _c('div', {
    staticClass: "p-0"
  }, [_c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Status Kapal Sesuai INAPORTNET')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.status_kapal || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor RPKRO')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(_vm._s(_vm.form.schedule.no_rpkro || '-'))])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor RKBM')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_rkbm || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor PBMBB')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_pbmbb || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor SPOG')) + " (Kedatangan) ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_spog || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor SPOG')) + " (Keberangkatan) ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_spog_brk || '-') + " ")])])])])]), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('div', [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.name_tug_boat) + " ")])], 1)]), _c('div', {
    staticClass: "p-0"
  }, [_c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Status Kapal Sesuai INAPORTNET')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.status_kapal_tb || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor RPKRO')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_rpkro_tb || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor RKBM')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_spog_tb || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor SPOG')) + " (Kedatangan) ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_spog_tb || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor SPOG')) + " (Keberangkatan) ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.schedule.no_spog_tb_brk || '-') + " ")])])])])]) : _vm._e()])]), _c('hr', {
    staticClass: "invoice-spacing"
  }), _c('b-card-body', {
    staticClass: "invoice-padding pt-0"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Note: ")]), _c('span', [_vm._v(" It was a pleasure working with you and your team. We hope you will keep us in mind for future shipping. Thank You! ")])])], 1)], 1), _c('b-col', {
    staticClass: "invoice-actions",
    attrs: {
      "cols": "12",
      "md": "3",
      "xl": "2"
    }
  }, [_c('b-card', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "outline-secondary",
      "block": "",
      "to": _vm.$router.currentRoute.params.previous_link
    }
  }, [_vm._v(" Kembali ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "success",
      "block": "",
      "to": {
        name: 'dashboard-monitoring-rrv-hulu-migas-view',
        params: {
          id: _vm.form.form_chain_hm_id,
          api: 'form_chain_hm',
          previous_link: _vm.$router.currentRoute
        }
      }
    }
  }, [_vm._v(" Realisasikan ")])], 1), true ? _c('b-card', {
    staticClass: "custom-verification",
    staticStyle: {
      "height": "30em !important"
    }
  }, [_c('h3', [_c('b-spinner', {
    attrs: {
      "small": "",
      "variant": "warning",
      "type": "grow"
    }
  }), _vm._v(" Verification "), _c('b-spinner', {
    attrs: {
      "small": "",
      "variant": "warning",
      "type": "grow"
    }
  })], 1), _c('hr'), _c('vue-perfect-scrollbar', {
    staticClass: "ps-customizer-area scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('ol', [!_vm.form.schedule.no_rpkro || !_vm.form.schedule.no_spog || !_vm.form.schedule.kegiatan_start_time ? _c('b-badge', {
    staticClass: "d-block",
    attrs: {
      "variant": 'light-warning'
    }
  }, [_vm._v(" " + _vm._s(_vm.form.dm_river_boat_hm.boat_name) + " ")]) : _vm._e(), _vm.form.schedule.status_kapal != 'PT. SAL' ? _c('li', [_c('span', [_vm._v(_vm._s(_vm.$t('Status Kapal Sesuai INAPORTNET')))]), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e(), !_vm.form.schedule.no_rkbm ? _c('li', [_c('span', {
    domProps: {
      "innerHTML": _vm._s('Belum mengisi Nomor RKBM')
    }
  }), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e(), !_vm.form.schedule.no_pbmbb ? _c('li', [_c('span', {
    domProps: {
      "innerHTML": _vm._s('Belum mengisi Nomor PBMBB')
    }
  }), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e(), !_vm.form.schedule.no_spog ? _c('li', [_c('span', {
    domProps: {
      "innerHTML": _vm._s('Belum mengisi Nomor SPOG')
    }
  }), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e(), !_vm.form.schedule.kegiatan_start_time ? _c('li', [_c('span', {
    domProps: {
      "innerHTML": _vm._s('Belum mengisi waktu kegiatan')
    }
  }), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e(), _vm.form.schedule.jenis_kapal === 'Barge' ? _c('span', [!_vm.form.schedule.no_rpkro_tb || !_vm.form.schedule.no_spog_tb || !_vm.form.schedule.kegiatan_start_time_tb ? _c('b-badge', {
    staticClass: "d-block",
    attrs: {
      "variant": 'light-warning'
    }
  }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.name_tug_boat) + " ")]) : _vm._e(), _vm.form.schedule.status_kapal_tb != 'PT. SAL' ? _c('li', [_c('span', [_vm._v(_vm._s(_vm.$t('Status Kapal Sesuai INAPORTNET')))]), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e(), !_vm.form.schedule.no_rpkro_tb ? _c('li', [_c('span', {
    domProps: {
      "innerHTML": _vm._s('Belum mengisi Nomor RPKRO (Menyetujui boleh mengisi SPOG)')
    }
  }), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e(), !_vm.form.schedule.no_rkbm_tb ? _c('li', [_c('span', {
    domProps: {
      "innerHTML": _vm._s('Belum mengisi Nomor RKBM')
    }
  }), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e(), !_vm.form.schedule.no_pbmbb_tb ? _c('li', [_c('span', {
    domProps: {
      "innerHTML": _vm._s('Belum mengisi Nomor PBMBB')
    }
  }), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e(), !_vm.form.schedule.no_spog_tb ? _c('li', [_c('span', {
    domProps: {
      "innerHTML": _vm._s('Belum mengisi Nomor SPOG')
    }
  }), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e(), !_vm.form.schedule.kegiatan_start_time_tb ? _c('li', [_c('span', {
    domProps: {
      "innerHTML": _vm._s('Belum mengisi waktu kegiatan')
    }
  }), _c('feather-icon', {
    staticClass: "ml-25",
    attrs: {
      "icon": 'XCircleIcon'
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1)])], 1) : _vm._e()], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }